import { Configuration } from './configuration';

export const GET_KEY_SUCCESS = 'GET_KEY_SUCCESS';
export const GET_KEY_FAIL = 'GET_KEY_FAIL';
export const DELETE_KEY_SUCCESS = 'DELETE_KEY_SUCCESS';
export const DELETE_KEY_FAIL = ' DELETE_KEY_FAIL';
export const UPDATE_KEY_SUCCESS = 'UPDATE_KEY_SUCCESS';
export const UPDATE_KEY_FAIL = 'UPDATE_KEY_FAIL';
export const CREATE_KEY_SUCCESS = 'REATE_KEY_SUCCESS';
export const CREATE_KEY_FAIL = 'CREATE_KEY_FAIL';

export interface APIKeysStates {
  loading: boolean;
  apiKeys?: APIKeyDTO[] | null | undefined | any;
}

export interface APIKeyPost {
  client: string;
  isActive?: boolean;
}

export interface APIKeyDTO {
  id: string;
  client: string;
  isActive: boolean;
  apiKey?: string;
  createdAt: Date;
  udatedAt: Date;
  configuration?: Configuration;
}

export interface APIKeyUpdate {
  id: string;
  isActive?: boolean;
}

export interface GetKeysAction {
  type: typeof GET_KEY_SUCCESS;
  payload: APIKeyDTO[];
}

export interface ErrorGetKeysAction {
  type: typeof GET_KEY_FAIL;
  payload: null;
}

export interface CreateKeysAction {
  type: typeof CREATE_KEY_SUCCESS;
  payload: APIKeyDTO;
}

export interface ErrorCreateKeysAction {
  type: typeof CREATE_KEY_FAIL;
  payload: null;
}

export interface UpdateKeysAction {
  type: typeof UPDATE_KEY_SUCCESS;
  payload: APIKeyDTO[];
}

export interface ErrorUpdateKeysAction {
  type: typeof UPDATE_KEY_FAIL;
  payload: null;
}

export interface DeleteKeysAction {
  type: typeof DELETE_KEY_SUCCESS;
  payload: APIKeyDTO[];
}

export interface ErrorDeleteKeysAction {
  type: typeof DELETE_KEY_FAIL;
  payload: null;
}

export type APIKeyType =
  | GetKeysAction
  | ErrorGetKeysAction
  | CreateKeysAction
  | ErrorCreateKeysAction
  | UpdateKeysAction
  | ErrorUpdateKeysAction
  | DeleteKeysAction
  | ErrorDeleteKeysAction;
