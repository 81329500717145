import React from 'react';
import { Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useRole } from '../../hooks/useRole';
import { Loading, ThemeButton } from '../../layout/App.components';
import { APIKeyDTO, APIKeysStates } from '../../services/types/APIKeys';
import { useSelector } from 'react-redux';
import { reduxState } from '../../services/types/reduxStates';
import { getAPIKeys } from '../../services/actions/api-keys';
import { createApiKeysData, Data } from '../../interfaces/api-keys';
import { DataTable, TableOption } from '../../layout/ui/data-table/DataTable';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { CreateApiKeyModal } from './CreateAPIKey';

export const APIGoupListPage: React.FC = (): JSX.Element => {
  const { apiKeys, loading }: APIKeysStates = useSelector((state: reduxState) => state.apiKeys);
  const dispatch = useDispatch();
  const { isSystemAdmin, isAdmin } = useRole();
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    dispatch(getAPIKeys());
  }, [dispatch]);

  const handleClose = (): void => {
    setOpen(false);
  };


  let rows: Data[] | [] = [];

  if (!loading && apiKeys) {
    rows = apiKeys
      .map((key: APIKeyDTO) =>
        createApiKeysData(
          key.id,
          key.client,
          key.isActive,
          key.createdAt,
          key.udatedAt
        ),
      );
  }

  const tableHeader: string[] = [
    'Client',
    'Status',
    'Created',
    'Edit'
  ];

  const tableOption: TableOption<string[], any[]> = {
    tableHeader,
    tableData: rows,
  };


  return (
    <>
      {loading && !apiKeys ?
        <Loading />
        :
        <>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <section className="list-page-header">

                <div className="create-btn" onClick={() => setOpen(true)}>
                  {(isSystemAdmin || isAdmin) &&
                    <ThemeButton
                      text="Create New APIKey"
                      clases="tc-btn"
                      color="primary"
                      variant="contained"
                      />}
                </div>
              </section>
              <CreateApiKeyModal isOpen={open} handleClose={handleClose} />
            </Grid>

            <Grid item xs={12} >
              <DataTable
                isActiveDelete={false}
                tableData={tableOption}
                generateCell={(row, column) => {
                  switch (column) {
                    case 'client': return row.client;
                    case 'status': return row.status ? 'True' : 'False';
                    case 'created': return row.created;
                    case 'edit': {
                      return (
                        <div className="edit-wrap">
                          <div className="icon">
                            <EditOutlinedIcon />
                          </div>
                        </div>
                      )
                    }
                    
                    default:
                      return null
                  }
                }}
                />
              </Grid>
          </Grid>
        </>
      }
    </>
  )
}
