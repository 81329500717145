import {
  LogedUserStates,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_ERROR,
} from '../types/User';
import { UserActionTypes } from '../types/actions';

const initialState: LogedUserStates = {
  loading: true,
  user: [],
};

export const userReducer = (
  state = initialState,
  action: UserActionTypes,
): LogedUserStates => {
  const { type, payload } = action;

  switch (type) {
    case GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        user: payload,
      };
    case GET_USER_PROFILE_ERROR:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
