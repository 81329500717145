import * as React from 'react';

function useFormValidation(initialState: any, validate: any, cb: Function) {
  const [values, setValues] = React.useState(initialState);
  const [errors, setErrors] = React.useState<any>({});
  const [isSubmitting, setSubmitting] = React.useState(false);

  const checkErrors = React.useCallback(() => {
    if (isSubmitting) {
      const noErrors: any = Object.keys(errors).length === 0;
      if (noErrors) {
        cb();
      } else {
        setSubmitting(false);
      }
    }
  }, [errors, isSubmitting, cb])

  React.useEffect(() => {
    checkErrors()
  }, [checkErrors]);

  const handleChange = (_event?: any) => {
    setValues({
      ...values,
      [_event.target.name]: _event.target.value,
    });
  };

  const handleBlur = () => {
    const validationErrors = validate(values);
    setErrors(validationErrors);
  };

  const handleSubmit = (_event: any) => {
    _event.preventDefault();
    const validationErrors = validate(values);
    setErrors(validationErrors);
    setSubmitting(true);
  };

  const resetValues = (resetAll: boolean = false, resetValues: any[] = []) => {
    if (!resetAll) {
      const reset = {
        ...values
      };

      resetValues.forEach((val) => {
        reset[val] = initialState[val];
      });

      setValues(reset);

    } else {
      setValues(initialState);
    }
  }

  return {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    setValues,
    errors,
    isSubmitting,
    setSubmitting,
    resetValues
  };
}

export default useFormValidation;
