import './user-list.scss';

import * as React from 'react';

import {Data, createUserData} from "../../interfaces/userData"
import {
  DataTable,
  Loading,
  ThemeButton,
} from '../../layout/App.components';
import { MenuItem, Select } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import { CreateUserModal } from './CreateUserModal';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import {Link} from 'react-router-dom';
import { TableOption } from '../../layout/ui/data-table/DataTable';
import { User } from '../../services/types/User';
import { deleteMultipleEmployees } from '../../services/actions/employees';
import { getEmployees } from '../../services/actions/employees';
import { useRole } from '../../hooks/useRole';

export const UserListPage: React.FC = (): JSX.Element => {
  const { users, loading } = useSelector((state: any) => state.employees);
  const { isSystemAdmin, isAdmin } = useRole();
  const [userStatus, setUserStatus] = React.useState<string | unknown>('');
  const dispatch = useDispatch();

  //modal
  const [open, setOpen] = React.useState(false);
  const handleClose = (): void => {
    setOpen(false);
  };

  React.useEffect(() => {
    dispatch(getEmployees());
  }, [dispatch]);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setUserStatus(event.target.value);
  };

  const filterUserData = (user: User) => {
    switch (userStatus) {
      case 'reader':
        return user.role === 'Reader';
      case 'admin':
        return user.role === 'Admin';
      case 'systemAdmin':
        return user.role === 'SystemAdmin';
      default:
        return user;
    }
  };

  let rows: Data[] | [] = [];

  if (!loading && users) {
    rows = users
      .filter((user: User) => filterUserData(user))
      .map((user: User) =>
        createUserData(
          user.id,
          user.avatar,
          user.isActive,
          user.role,
          `${user.firstName} ${user.lastName}`,
          user.phoneNumber,
          user.email,
        ),
      );
  }

  const tableHeader:string[] = [
    'Status',
    'Role',
    'Username',
    'Phone Number',
    'Email',
    'Edit',
  ];

  const tableOption: TableOption<string[], Data[]> = {
    tableHeader: tableHeader,
    tableData: rows,
  };

  return (
    <>
      {loading && !users ? (
        <Loading />
      ) : (
        <>
          <section className="list-page-header">
            <div className="filter">
              <label className="label" title="user role">
                Role:
              </label>
              <Select
                value={userStatus}
                onChange={handleChange}
                displayEmpty
                name="status">
                <MenuItem value="">
                  <em>All</em>
                </MenuItem>
                <MenuItem value="reader">Reader</MenuItem>
                <MenuItem value="admin">Admin</MenuItem>
                <MenuItem value="systemAdmin">System Admin</MenuItem>
              </Select>
            </div>
            <div className="create-btn" onClick={() => setOpen(true)}>
              <ThemeButton
                text="Add new Employee"
                clases="tc-btn"
                color="primary"
                variant="contained"
              />
            </div>
          </section>

          <CreateUserModal isOpen={open} handleClose={handleClose} />

          <DataTable
            handleMultipleDelete={(selected) => deleteMultipleEmployees(selected)}
            tableData={tableOption}
            generateCell={(row,column) => {
              const details:  string = "/dashboard/user-details/".concat(row.id);
              switch(column){
                case "email": return row.email;
                case "phone number": return row.phoneNumber;
                case "role": return row.role;
                case "username": {
                  return (
                    <div className="user-wrap">
                      <Link to={details} className="edit-wrap">
                        <span className="name dot-wrap" title={row.username}>
                          {' '}
                          {row.username}{' '}
                        </span>
                      </Link>
                    </div>
                  );
                }
                case "status":{
                  const value: string = row.status ? "Active" : "Archived";
                  return (
                    <span className={"ont-badge ".concat(value.toLowerCase())}>
                      {value}
                    </span>
                  );
                }
                case "edit":{
                  return (
                    <Link to={details} className="edit-wrap">
                    <div className="edit-text">
                      {(isSystemAdmin || isAdmin) ? 'Edit' : 'View'}
                    </div>
                    <div className="icon">
                      <EditOutlinedIcon />
                    </div>
                  </Link>
                  )
                }
                default:
                  return null
              }
            }}
          />
        </>
      )}
    </>
  );
};
