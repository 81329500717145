import {
  GET_PATIENTS_FAIL,
  GET_PATIENTS_SUCCESS,
  PatientsState,
  GetPatientAction,
} from '../types/Patients';

const initialState: PatientsState = {
  count: 0,
  genders: [],
};

export const patientReducer = (
  state = initialState,
  action: GetPatientAction,
): PatientsState => {
  const { type, payload } = action;

  switch (type) {
    case GET_PATIENTS_SUCCESS: {
      return {
        count: payload.count,
        genders: payload.genders,
      };
    }
    case GET_PATIENTS_FAIL:
      return {
        ...state,
      };
    default:
      return state;
  }
};
