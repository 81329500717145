/* eslint-disable react/no-array-index-key */
import React, { useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Pharmacy, PharmacyFormType } from '../../services/types/Pharmacy';
import { FormInput, FormPhoneNumber, FormSelect, ThemeButton } from '../../layout/App.components';
import { Divider, Grid, MenuItem, Typography } from '@material-ui/core';
import { cities } from '../../util/cityAndState';
import { useRole } from '../../hooks/useRole';
import { useDispatch, useSelector } from 'react-redux';
import { getAPIKeys } from '../../services/actions/api-keys';
import { generateConfiguration, getChains, updatePharmacy } from '../../services/actions/pharmacy';
import { APIKeyDTO, APIKeysStates } from '../../services/types/APIKeys';
import { PharmacyChain } from '../../services/types/chain';
import { reduxState } from '../../services/types/reduxStates';
import { capital_letter } from '../../util/helpers';
import { regularAlert } from '../../util/systemAlert';

type Props = {
  pharmacy: Pharmacy;
}

const schema = yup.object().shape({
  name: yup.string().required(),
  address: yup.string().required(),
  state: yup.string().required(),
  town: yup.string().required().label('City'),
  zipCode: yup.number().required(),
  email: yup.string().email().required(),
  phoneNumber: yup.string().required().length(14)
});


export const PharmacyEditForm: React.FC<Props> = ({ pharmacy }): JSX.Element => {
  const { isSystemAdmin, isAdmin } = useRole();
  const { chains, loading }: { chains: PharmacyChain[], loading: boolean } = useSelector((state: reduxState) => state.chains);
  const { apiKeys, loading: keyloading }: APIKeysStates = useSelector((state: reduxState) => state.apiKeys);
  const dispatch = useDispatch();

  const { handleSubmit, setValue, control, formState: { errors } } = useForm<PharmacyFormType>({
    resolver: yupResolver(schema)
  });

  const hasRx30 = useWatch({
    control,
    name: 'hasRx30Input',
    defaultValue: pharmacy.hasRx30 ? 'YES' : 'NO'
  });

  useEffect(() => {
    dispatch(getChains());
    dispatch(getAPIKeys());
  }, [dispatch]);

  useEffect(() => {
    setValue('name', pharmacy.name);
    setValue('address', pharmacy.address);
    setValue('state', pharmacy.state);
    setValue('town', pharmacy.town);
    setValue('zipCode', pharmacy.zipCode);
    setValue('email', pharmacy.email);
    setValue('phoneNumber', pharmacy.phoneNumber);
    setValue('faxNumber', pharmacy.faxNumber);
    setValue('chain', pharmacy.chain ? pharmacy.chain.id : '');
    setValue('apiKey', pharmacy.apiKey);
    setValue('latitude', pharmacy.latitude ? pharmacy.latitude : '');
    setValue('longitude', pharmacy.longitude ? pharmacy.longitude : '');
    setValue('rx30Number', hasRx30 === 'YES' ? pharmacy.rx30Number : '');
    setValue('hasRx30Input', hasRx30);
  }, [pharmacy, setValue, hasRx30]);


  const saveData = (values: PharmacyFormType) => {
    regularAlert({
      callback: () => dispatch(
        updatePharmacy(pharmacy.id, {
          name: capital_letter(values.name),
          address: values.address,
          town: values.town,
          state: values.state,
          zipCode: values.zipCode,
          longitude: values.longitude,
          latitude: values.latitude,
          phoneNumber: values.phoneNumber,
          faxNumber: values.faxNumber,
          email: values.email.toLocaleLowerCase(),
          hasRx30: values.hasRx30Input === 'YES',
          rx30Number: values.rx30Number,
          chain: values.chain || null,
          apiKey: values.apiKey || null
        }),
      ),

      successConfirmation: {
        title: 'Updated!',
        text: 'Pharmacy has been Updated Successfully.',
      }
    });
  }

  const generateConfigirationAction = () => {
    // generateConfiguration
    regularAlert({
      callback: () => dispatch(generateConfiguration({ pharmacyId: pharmacy.id })),

      successConfirmation: {
        title: 'Updated!',
        text: 'Configuration has been Generated Successfully.',
      }
    });
  }

  return (
    <>
      <form onSubmit={handleSubmit(saveData)}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <div style={{
              display: 'flex',
              padding: '2% 0px',
            }}>
              <Typography variant="h5" gutterBottom style={{
                marginRight: 'auto'
              }} >
                Pharmacy Details
              </Typography>
              <div style={{
                marginLeft: 'auto'
              }}>
                {(isSystemAdmin || isAdmin) &&
                  <span onClick={generateConfigirationAction}>
                    <ThemeButton
                      text="Generate Configuration"
                      clases="tc-btn"
                      color="primary"
                      variant="contained"
                      />
                  </span>
                }
              </div>
            </div>

            <Divider variant="fullWidth" component="hr" />
          </Grid>
        </Grid>
        <Grid container spacing={3} className="pharmacy__details">
          <Grid item xs={12} md={6}>
            <div className="input_container">
              <FormInput
                fullWidth
                control={control}
                name="name"
                label="Name"
                errors={{
                  error: !!errors.name,
                  message: errors.name?.message
                }}
                />
            </div>
            <div className="input_container">

              <FormSelect
                fullWidth
                control={control}
                name="state"
                label="State"
                errors={{
                  error: !!errors.state,
                  message: errors.state?.message
                }}>
                <MenuItem value="Puerto Rico">Puerto Rico</MenuItem>
              </FormSelect>
            </div>
            <div className="input_container">
              <FormInput
                fullWidth
                control={control}
                name="zipCode"
                label="Zip Code"
                errors={{
                  error: !!errors.zipCode,
                  message: errors.zipCode?.message
                }}
                />
            </div>
            <div className="input_container">
              <FormPhoneNumber
                fullWidth
                control={control}
                name="phoneNumber"
                label="Phone Number"
                errors={{
                  error: !!errors.phoneNumber,
                  message: errors.phoneNumber?.message
                }}
                />
            </div>
            <div className="input_container">
              <FormInput
                fullWidth
                control={control}
                name="latitude"
                label="Latitude"
                errors={{
                  error: !!errors.latitude,
                  message: errors.latitude?.message
                }}
                />
            </div>

            <div className="input_container">
              <FormSelect
                fullWidth
                control={control}
                name="apiKey"
                label="App Client Group"
                errors={{
                  error: !!errors.apiKey,
                  message: errors.apiKey?.message
                }}>
                <MenuItem value=""> --- Select a API Group --- </MenuItem>
                {(!keyloading && apiKeys && apiKeys.length > 0) &&
                  apiKeys.map((api: APIKeyDTO) =>
                    <MenuItem key={api.id} value={api.apiKey}>{api.client}</MenuItem>
                  )}
              </FormSelect>
            </div>
            <div className="input_container">
              <FormSelect
                fullWidth
                control={control}
                name="hasRx30Input"
                label="Has Rx30?"
                errors={{
                  error: !!errors.hasRx30Input,
                  message: errors.hasRx30Input?.message
                }}>
                <MenuItem value="NO">No</MenuItem>
                <MenuItem value="YES">Yes</MenuItem>
              </FormSelect>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className="input_container">
              <FormInput
                fullWidth
                control={control}
                name="address"
                label="Address"
                errors={{
                  error: !!errors.address,
                  message: errors.address?.message
                }}
                />
            </div>
            <div className="input_container">
              <FormSelect
                fullWidth
                control={control}
                name="town"
                label="City"
                errors={{
                  error: !!errors.town,
                  message: errors.town?.message
                }}>
                <MenuItem value=""> --- Please Select --- </MenuItem>
                {cities.map((city: string, i: number) =>
                  <MenuItem key={i} value={city}>{city}</MenuItem>
                )}
              </FormSelect>
            </div>
            <div className="input_container">
              <FormInput
                fullWidth
                control={control}
                name="email"
                label="email"
                errors={{
                  error: !!errors.email,
                  message: errors.email?.message
                }}
                />
            </div>
            <div className="input_container">
              <FormPhoneNumber
                fullWidth
                control={control}
                name="faxNumber"
                label="Fax Number"
                errors={{
                  error: !!errors.faxNumber,
                  message: errors.faxNumber?.message
                }}
                />
            </div>
            <div className="input_container">
              <FormInput
                fullWidth
                control={control}
                name="longitude"
                label="Longitude"
                errors={{
                  error: !!errors.longitude,
                  message: errors.longitude?.message
                }}
                />
            </div>
            <div className="input_container">
              <FormSelect
                fullWidth
                control={control}
                name="chain"
                label="Pharmacy Chain"
                errors={{
                  error: !!errors.chain,
                  message: errors.chain?.message
                }}>
                <MenuItem value=""> --- Select a Chain --- </MenuItem>
                {(!loading && chains && chains.length > 0) &&
                  chains.map((chain: PharmacyChain) =>
                    <MenuItem key={chain.id} value={chain.id}>{chain.name}</MenuItem>
                  )}
              </FormSelect>
            </div>
            <div className="input_container">
              <FormInput
                fullWidth
                control={control}
                name="rx30Number"
                label="Rx30 Number"
                disabled={hasRx30 !== 'YES'}
                errors={{
                  error: !!errors.rx30Number,
                  message: errors.rx30Number?.message
                }}
                />
            </div>

          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} style={{
            display: 'flex'
          }}>
            <div style={{
              marginLeft: 'auto'
            }}>
              {(isSystemAdmin || isAdmin) &&
                <ThemeButton
                  text="Save Details"
                  clases="tc-btn"
                  color="primary"
                  variant="contained"
                  />}
            </div>

          </Grid>
        </Grid>
      </form>
    </>
  );
}
