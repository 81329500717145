import { pharmacyReportsReducer, reportReducer } from './reducers/report';

import { alertReducer } from './reducers/alert';
import { apiKeysReducer } from './reducers/api-key';
import { authReducer } from './reducers/auth';
import { chainReducer } from './reducers/chain';
import { combineReducers } from 'redux';
import { employeesReducer } from './reducers/employees';
import { patientReducer } from './reducers/patients';
import { pharmacistReducer } from './reducers/pharmacists';
import { pharmacyConfigurationReducer } from './reducers/configuration';
import { pharmacyReducer } from './reducers/pharmacy';
import { searchReducer } from './reducers/search';
import { userReducer } from './reducers/user';

export const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  employees: employeesReducer,
  pharmacies: pharmacyReducer,
  alert: alertReducer,
  search: searchReducer,
  patients: patientReducer,
  pharmacists: pharmacistReducer,
  report: reportReducer,
  pharmacyReport: pharmacyReportsReducer,
  chains: chainReducer,
  apiKeys: apiKeysReducer,
  pharmacyConfiguration: pharmacyConfigurationReducer
});
