import * as React from 'react';
import {
  Dialog,
  DialogContent,
  Typography,
  Box,
  makeStyles,
  Tabs,
  Tab,
} from '@material-ui/core';
import {
  DialogTitle,
} from '../../layout/App.components';
import { AddNewPharmacist } from './AddNewPharmacist';
import { AddPharmacistFromList } from './AddPharmacistFromList';
import { Pharmacist } from '../../services/types/Pharmacist';


interface EditModalProps {
  isOpen: boolean;
  handleClose: () => void;
  pharmacyId: string;
  pharmacistList: Pharmacist[];
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`add-pharmacist-${index}`}
      aria-labelledby={`add-tab-${index}`}
      {...other}>
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    height: 550,
  },
}));

export const AddPharmacistModal: React.FC<EditModalProps> = ({
  isOpen,
  handleClose,
  pharmacyId,
  pharmacistList
}: EditModalProps): JSX.Element => {
  const classes = useStyles();
  const [valueTab, setValueTab] = React.useState(0);

  const handleChangeTab = (_event: React.ChangeEvent<{}>, newValue: number) => {
    setValueTab(newValue);
  };

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title">
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Add Pharmacist
        </DialogTitle>
        <DialogContent dividers>
          <div className="edit-form-container">
            <div className={classes.root}>
              <Tabs value={valueTab} onChange={handleChangeTab} aria-label="add pharmacist tabs"
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth">
                <Tab label="Add New" {...a11yProps(0)} />
                <Tab label="Add from Existing" {...a11yProps(1)} />
              </Tabs>

              <TabPanel value={valueTab} index={0}>
                <AddNewPharmacist pharmacyId={pharmacyId} handleClose={handleClose} />
              </TabPanel>
              
              <TabPanel value={valueTab} index={1}>
                <AddPharmacistFromList pharmacistList={pharmacistList} handleClose={handleClose} pharmacyId={pharmacyId} />
              </TabPanel>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};
