import {
  GET_PHARMACIST_FAIL,
  GET_PHARMACIST_SUCCESS,
} from '../types/Pharmacist';

import { API_HOST } from '../../../config';
import { AnyAction } from 'redux';
import { Dispatch } from 'react';
import axios from 'axios';
import { getPharmacies } from './pharmacy';
import { loadUser } from './auth';

export const createPharmacists = (data: any): any => async (
  dispatch: Dispatch<AnyAction>,
): Promise<any> => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify(data);

  try {
    await axios.post(
      `${API_HOST}/ontime_admin/pharmacists/create`,
      body,
      config,
    );

    dispatch(getPharmacies());
    dispatch(loadUser());
    return {
      action: true,
      message: '',
    };
  } catch (error: any) {
    return {
      action: false,
      message:
        error.response && error.response.data
          ? error.response.data.message
          : '',
    };
  }
};

export const getPharmacist = () => async (
  dispatch: Dispatch<AnyAction>,
): Promise<any> => {
  try {
    const response = await axios.get(`${API_HOST}/ontime_admin/pharmacists`);

    dispatch({
      type: GET_PHARMACIST_SUCCESS,
      payload: response.data,
    });

    dispatch(getPharmacies());
    dispatch(loadUser());

    return {
      data: response.data,
      success: true,
    };
  } catch (error: any) {
    dispatch({
      type: GET_PHARMACIST_FAIL,
    });

    return {
      success: false,
      message:
        error.response && error.response.data
          ? error.response.data.message
          : '',
    };
  }
};

export const removePharmacistPharmacy = (data: {
  id: string;
  pharmacyId: string;
}) => async (dispatch: Dispatch<AnyAction>): Promise<any> => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify(data);
  try {
    await axios.post(
      `${API_HOST}/ontime_admin/pharmacists/remove/pharmacy`,
      body,
      config,
    );

    dispatch(getPharmacies());
    dispatch(loadUser());

    return {
      action: true,
      message: '',
    };
  } catch (error: any) {
    return {
      action: false,
      message:
        error.response && error.response.data
          ? error.response.data.message
          : '',
    };
  }
};

export const addPharmacistPharmacy = (data: {
  id: string;
  pharmacyId: string;
}) => async (dispatch: Dispatch<AnyAction>): Promise<any> => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify(data);
  try {
    await axios.post(
      `${API_HOST}/ontime_admin/pharmacists/add/pharmacy`,
      body,
      config,
    );

    dispatch(getPharmacies());
    dispatch(loadUser());

    return {
      action: true,
      message: '',
    };
  } catch (error: any) {
    return {
      action: false,
      message:
        error.response && error.response.data
          ? error.response.data.message
          : '',
    };
  }
};
