import { Dispatch } from 'react';

import { AnyAction } from 'redux';

import { GET_EMPLOYEES_SUCCESS } from '../types/Employees';

import { GET_PHARMACIES_SUCCESS } from '../types/Pharmacy';

export const searchAction = (value: string, list: string): any => (
  dispatch: Dispatch<AnyAction>,
): void => {
  if (list === '/dashboard/user-list') {
    dispatch({
      type: GET_EMPLOYEES_SUCCESS,
      payload: value,
    });
  }
  if (list === '/dashboard/pharmacies-list') {
    dispatch({
      type: GET_PHARMACIES_SUCCESS,
      payload: value,
    });
  }
};
