import './pharmacy-details.scss';

/* eslint-disable jsx-a11y/label-has-associated-control */
import * as React from 'react';

import { Alert, AlertTitle } from '@material-ui/lab';
import {
  Breadcrumbs,
  Card,
  CardContent,
  Grid,
  Typography,
} from '@material-ui/core';
import { confirmationAlert, regularAlert } from '../../util/systemAlert';
import {
  getPharmacies,
  getPharmacyConfiguration,
  statusPharmacy,
  uploadImage,
} from '../../services/actions/pharmacy';
import { useDispatch, useSelector } from 'react-redux';

import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { Link } from 'react-router-dom';
import { Loading } from '../../layout/App.components';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import { PharmacyConfigurationForm } from './PharmacyConfigurationForm';
import { PharmacyEditForm } from './PharmacyEditForm';
import { PharmacyEditModal } from './PharmacyEditModal';
import { RouteComponentProps } from 'react-router';
import { pharmacyReports } from '../../services/actions/report';
import { reduxState } from '../../services/types/reduxStates';
import { useRole } from '../../hooks/useRole';

// import { PharmacyConfigurationForm } from './PharmacyConfigurationForm';

export const PharmacyDetailsPage: React.FC<RouteComponentProps<{}>> = (
  props: RouteComponentProps<{}> | any,
): JSX.Element => {
  const { id } = props.match.params;
  const { isSystemAdmin, isAdmin } = useRole();
  const [image, setImage] = React.useState({ preview: '', raw: '' });
  const [open, setOpen] = React.useState(false);

  const dispatch = useDispatch();

  const { loading: reportLoading, reports } = useSelector(
    (state: reduxState) => state.pharmacyReport,
  );

  const { pharmacy, loading } = useSelector((state: any) => ({
    pharmacy: state.pharmacies.pharmacies.find(
      (pharmacyData: any) => pharmacyData.id === id,
    ),
    loading: state.pharmacies.loading,
  }));

  const { configuration, loading: configLog } = useSelector(
    (state: reduxState) => state.pharmacyConfiguration,
  );

  const handleClose = (): void => {
    setOpen(false);
  };

  React.useEffect(() => {
    dispatch(getPharmacies());
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(getPharmacyConfiguration({ pharmacyId: id }));
  }, [dispatch, id]);

  const loadReports = React.useCallback(async () => {
    if (!loading && pharmacy) {
      dispatch(pharmacyReports(pharmacy.id));
    }
  }, [dispatch, loading, pharmacy]);

  React.useEffect(() => {
    loadReports();
  }, [loadReports]);

  const handleStatus = () => {
    const status = {
      isActive: !pharmacy.isActive,
    };

    const statusText = !pharmacy.isActive ? 'Active' : 'Archive';
    confirmationAlert({
      title: `Are you sure you want to ${statusText} this Pharmacy?`,
      text: '',
      confirmButtonText: `Yes, ${statusText} this Pharmacy`,
      successConfirmation: {
        title: 'Status Change',
        text: 'Pharmacy status has been updated.',
      },
      callback: () => dispatch(statusPharmacy(id, status)),
    });
  };

  const acceptedTypes: string[] = ['image/png', 'image/jpg', 'image/jpeg'];

  const isValidFileType = (fileType: string): boolean =>
    acceptedTypes.includes(fileType);
  const getFileExtension = (filename: string) => filename.split('.').pop();

  const handleUploadClick = (e: any) => {
    e.preventDefault();

    if (typeof e.target.files[0] !== 'undefined') {
      if (!isValidFileType(e.target.files[0].type)) {
        return;
      }

      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
      const fileName = `${pharmacy.id}.${getFileExtension(
        e.target.files[0].name,
      )}`;
      const file = new File([e.target.files[0]], fileName);

      const formData = new FormData();
      formData.append('file', file, fileName);
      formData.append('id', pharmacy.id);

      regularAlert({
        callback: () => dispatch(uploadImage(formData)),
        successConfirmation: {
          title: 'Updated!',
          text: 'Pharmacy image has been Updated Successfully.',
        },
      });
    }
  };

  return (
    <section className="pharmacy-details-page">
      {loading && pharmacy === undefined ? (
        <Loading />
      ) : (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Breadcrumbs aria-label="breadcrumb">
                <Link to="/dashboard/pharmacies-list">pharmacies List</Link>
                <Typography color="textPrimary">pharmacy details</Typography>
              </Breadcrumbs>
            </Grid>
            {!reportLoading && reports && reports?.pharmacistsCount <= 0 && (
              <Grid item xs={12}>
                <Alert severity="warning">
                  <AlertTitle>Warning</AlertTitle>
                  To enable the pharmacy, please add an owner.
                </Alert>
              </Grid>
            )}

            <Grid item xs={12} sm={6} md={4}>
              <Card className="contact-info">
                <CardContent>
                  <div className="header">
                    <div
                      className="image-container"
                      style={{
                        paddingTop: 50,
                      }}>
                      <div className="img-wrap">
                        <input
                          accept="image/*"
                          className="input"
                          id="contained-button-file"
                          multiple
                          type="file"
                          onChange={handleUploadClick}
                        />

                        <label
                          htmlFor="contained-button-file"
                          className="edit-btn">
                          {' '}
                          <EditOutlinedIcon />
                        </label>
                        <img
                          src={image.preview ? image.preview : pharmacy.logo}
                          alt={pharmacy.name}
                        />
                      </div>
                    </div>
                    <span className="name">{pharmacy.name}</span>
                    {pharmacy.hasRx30 && (
                      <span className="role">
                        Rx30 Key: {pharmacy.rx30Number}{' '}
                      </span>
                    )}

                    <span
                      className={`ont-badge ${
                        pharmacy.isActive ? 'active' : 'archived'
                      }`}>
                      {pharmacy.isActive ? 'Active' : 'Archived'}
                    </span>
                  </div>
                  {!reportLoading && reports && (
                    <div className="pharmacists-info">
                      <PeopleOutlineIcon className="icon" fontSize="large" />
                      <p className="text">
                        {reports?.pharmacistsCount} Pharmacists
                      </p>
                      <Link
                        to={`/dashboard/pharmacy-details/${pharmacy.id}/pharmacists`}
                        className="link">
                        View list
                      </Link>
                    </div>
                  )}
                  {(isSystemAdmin || isAdmin) && (
                    <div className="action-container">
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <div
                            style={{
                              marginTop: 30,
                              marginLeft: 'auto',
                              marginRight: 'auto',
                              width: '80%',
                            }}>
                            {pharmacy.isActive ? (
                              <div
                                className="action-btn archive"
                                onClick={handleStatus}>
                                Archive Pharmacy
                              </div>
                            ) : (
                              <div
                                className="action-btn active"
                                onClick={handleStatus}>
                                Active Pharmacy
                              </div>
                            )}
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  )}
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={8}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <Card className="contact-info">
                    <CardContent>
                      <PharmacyEditForm pharmacy={pharmacy} />
                    </CardContent>
                  </Card>
                </Grid>
                {!configLog && configuration && (
                  <Grid item xs={12} md={12}>
                    <Card className="contact-info">
                      <CardContent>
                        <PharmacyConfigurationForm
                          pharmactHasGlobalconfiguration={
                            pharmacy.useGlobalConfiguration
                          }
                          configuration={configuration}
                        />
                      </CardContent>
                    </Card>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
          <PharmacyEditModal
            isOpen={open}
            handleClose={handleClose}
            pharmacy={pharmacy}
            id={id}
          />
        </>
      )}
    </section>
  );
};
