export interface PharmacyData {
  id: string | undefined;
  logo: string | undefined;
  name: string | undefined;
  town: string | undefined;
  state: string | undefined;
  phoneNumber: string | undefined;
  hasRx30: boolean | undefined;
  status: boolean | undefined;
}

export const createPharmacyData = (
  id: string,
  logo: string,
  status: boolean,
  name: string,
  town: string,
  state: string,
  phoneNumber: string,
  hasRx30: boolean,
): PharmacyData => ({
  id,
  logo,
  status,
  name,
  town,
  state,
  phoneNumber,
  hasRx30,
});
