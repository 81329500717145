import {
  PharmacistStates,
  GET_PHARMACIST_SUCCESS,
  GET_PHARMACIST_FAIL,
} from '../types/Pharmacist';
import { PharmacistActionType } from '../types/actions';

const initialState: PharmacistStates = {
  loading: true,
  pharmacists: null,
};

export const pharmacistReducer = (
  state = initialState,
  action: PharmacistActionType,
): PharmacistStates => {
  const { type, payload } = action;

  switch (type) {
    case GET_PHARMACIST_SUCCESS:
      return {
        ...state,
        loading: false,
        pharmacists: payload,
      };
    case GET_PHARMACIST_FAIL:
      return {
        ...state,
        loading: false,
        pharmacists: null,
      };
    default:
      return state;
  }
};
