export const GET_PHARMACIST_SUCCESS = 'GET_PHARMACIST_SUCCESS';
export const GET_PHARMACIST_FAIL = 'GET_PHARMACIST_FAIL';

export interface Pharmacist {
  id: string;
  avatar: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  role: string;
  isActive: boolean;
  createdAt: Date;
  employNumber: string;
  pharmacy?: IPharmacyByPharmacist;
}

export interface IPharmacyByPharmacist {
  id: string;
  name: string;
  phoneNumber: string;
  email: string;
  pharmacy?: IPharmacyByPharmacist;
}

export interface PharmacistStates {
  loading: boolean;
  pharmacists?: Pharmacist[] | null | undefined | any;
}

export interface GetPharmacistAction {
  type: typeof GET_PHARMACIST_SUCCESS;
  payload: Pharmacist[];
}

export interface ErrorGetPharmacistAction {
  type: typeof GET_PHARMACIST_FAIL;
  payload: [];
}
