import {
  APIKeysStates,
  APIKeyType,
  DELETE_KEY_FAIL,
  DELETE_KEY_SUCCESS,
  GET_KEY_FAIL,
  GET_KEY_SUCCESS,
  UPDATE_KEY_FAIL,
  UPDATE_KEY_SUCCESS,
} from '../types/APIKeys';

const initialState: APIKeysStates = {
  loading: false,
  apiKeys: null,
};

export const apiKeysReducer = (
  state = initialState,
  action: APIKeyType,
): APIKeysStates => {
  const { type, payload } = action;

  switch (type) {
    case GET_KEY_SUCCESS:
    case UPDATE_KEY_SUCCESS:
    case DELETE_KEY_SUCCESS:
      return {
        ...state,
        loading: false,
        apiKeys: payload,
      };
    case GET_KEY_FAIL:
    case UPDATE_KEY_FAIL:
    case DELETE_KEY_FAIL:
      return {
        ...state,
        loading: false,
        apiKeys: [],
      };
    default:
      return state;
  }
};
