import * as React from 'react';
import './user-profile.scss';
import {
  Grid,
  Card,
  CardContent,
  makeStyles,
  Theme,
  createStyles,
  TextField,
} from '@material-ui/core';
import { ThemeButton, Loading } from '../../layout/App.components';
import useFormValidation from '../../hooks/useFormValidation';
import { validateChangePass } from '../../util/validation';
import { useSelector, useDispatch } from 'react-redux';
import { reduxState } from '../../services/types/reduxStates';
import { logedUserChangePassword } from '../../services/actions/user';
import { confirmationAlert } from '../../util/systemAlert';
import { UserProfileInformation } from './UserProfileInformation';
import { UserProfileInformationEdit } from './UserProfileInformationEdit';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      alignContent: 'center',
      height: '100vh',
    },
    container: {
      padding: theme.spacing(5, 3),
      maxWidth: '90%',
      boxShadow: '0 6px 18px rgba(0, 0, 0, 0.06)',
    },
    textField: {},
  }),
);

export const PageUserProfile: React.FC = (): JSX.Element => {
  const { user, loading } = useSelector((state: reduxState) => state.user);
  const [isEditMode, setEditMode] = React.useState(false);
  const [isSubmitEditFrom, setSubmitEditFrom] = React.useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();

  const initialStatePassword = {
    currentPassword: '',
    repeatNewPassword: '',
    newPassword: '',
  };

  const editeMode = (): void => {
    setEditMode(true);
  };
  const cancelEditeMode = (): void => {
    setEditMode(false);
  };

  const submitEditFrom = (): void => {
    setSubmitEditFrom(true);
  };

  const onSubmitPassword = async (): Promise<void> => {
    // dispatch(logedUserChangePassword(values, user.id));
    confirmationAlert({
      title: 'Are you sure you want to change your password?',
      text: '',
      confirmButtonText: 'Yes',
      successConfirmation: {
        title: 'Password Change',
        text: 'You password has been change succefully',
      },
      callback: async () => {
        cancelSubmitPassword();
        return dispatch(logedUserChangePassword(values, user.id));
      },
      redirect: () => cleanValuesPassword(),
      callbackIfError: () => cancelSubmitPassword(),
    });
  };
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    setValues,
    isSubmitting,
    setSubmitting,
    errors,
  } = useFormValidation(
    initialStatePassword,
    validateChangePass,
    onSubmitPassword,
  );

  const cleanValuesPassword = (): void => {
    setValues(initialStatePassword);
  };

  const cancelSubmitPassword = (): void => {
    setSubmitting(false);
  };

  return (
    <section className="user-details-page">
      {loading && !user ? 
        <Loading />
       : 
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4}>
            <Card className="contact-info">
              <CardContent>
                <div className="header">
                  <span className="name">
                    {user.firstName} {user.lastName}
                  </span>
                  <span className="role">{user.role} </span>

                  <span
                    className={`ont-badge ${
                      user.isActive ? 'active' : 'archived'
                    }`}>
                    {user.isActive ? 'Active' : 'Archived'}
                  </span>
                </div>

                {!isEditMode && 
                  <div className="detail-btn" onClick={editeMode}>
                    <ThemeButton
                      text="Edit Profile"
                      clases="tc-btn"
                      color="primary"
                      variant="contained"
                      />
                  </div>
                }
                {isEditMode && 
                  <>
                    <div className="detail-btn" onClick={submitEditFrom}>
                      <ThemeButton
                        text="Save Changes"
                        clases="tc-btn"
                        color="primary"
                        variant="contained"
                        />
                    </div>
                    <div className="cancel-btn" onClick={cancelEditeMode}>
                      <ThemeButton
                        text="Cancel"
                        clases="tc-btn"
                        color="primary"
                        />
                    </div>
                  </>
                }
              </CardContent>
            </Card>
          </Grid>
            <Grid item xs={12} sm={6} md={8}>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={6}>
                <Card className="contact-info">
                  <CardContent>
                    {!isEditMode && <UserProfileInformation user={user} />}
                    {isEditMode && 
                      <UserProfileInformationEdit
                        user={user}
                        submitEditFrom={isSubmitEditFrom}
                        setSubmitEditFrom={setSubmitEditFrom}
                        setEditMode={setEditMode}
                        />
                    }
                  </CardContent>
                </Card>
              </Grid>
                <Grid item xs={12} sm={12} md={6}>
                <Card className="contact-info">
                  <CardContent>
                    <div className="user-info">
                      <div className="contact-header">
                        <span>Change Password</span>
                      </div>
                      <div className="password-form">
                        <form onSubmit={handleSubmit} method="POST">
                          <TextField
                            error={errors.currentPassword && true}
                            id="currentPassword"
                            label={
                              errors.currentPassword
                                ? errors.currentPassword
                                : 'Current Password'
                            }
                            className={classes.textField}
                            type="password"
                            name="currentPassword"
                            autoComplete="currentPassword"
                            margin="normal"
                            fullWidth
                            value={values.currentPassword}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            />

                          <TextField
                            error={errors.newPassword && true}
                            id="newPassword"
                            label={
                              errors.newPassword
                                ? errors.newPassword
                                : 'New Password'
                            }
                            className={classes.textField}
                            type="password"
                            name="newPassword"
                            autoComplete="newPassword"
                            margin="normal"
                            fullWidth
                            value={values.newPassword}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            />

                          <TextField
                            error={errors.repeatNewPassword && true}
                            id="rrepeatNewPassword"
                            label={
                              errors.repeatNewPassword
                                ? errors.repeatNewPassword
                                : 'Confirm New Password'
                            }
                            className={classes.textField}
                            type="password"
                            name="repeatNewPassword"
                            autoComplete="repeatNewPassword"
                            margin="normal"
                            fullWidth
                            value={values.repeatNewPassword}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            />

                          <div className="detail-btn">
                            <ThemeButton
                              isDisabled={isSubmitting}
                              text={
                                isSubmitting ? 'Saving...' : 'Change Password'
                              }
                              clases="tc-btn"
                              color="primary"
                              variant="contained"
                              />
                          </div>
                        </form>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
    </section>
  );
};
