/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { FormCheckbox, FormInput, ThemeButton } from '../../layout/App.components';
import { Checkbox, Divider, FormControlLabel, Grid, Typography } from '@material-ui/core';
import { useRole } from '../../hooks/useRole';
// import { capital_letter } from '../../util/helpers';
import { regularAlert } from '../../util/systemAlert';
import { Configuration, DeliveryMethod, GlobaConfigurationType } from '../../services/types/configuration';
import { updatePharmacyConfiguration } from '../../services/actions/pharmacy';
import { useDispatch } from 'react-redux';



type Props = {
  pharmactHasGlobalconfiguration: boolean;
  configuration: Configuration;
}

const schema = yup.object().shape({
  webSite: yup.string().url(),
});


export const PharmacyConfigurationForm: React.FC<Props> = ({ pharmactHasGlobalconfiguration, configuration }): JSX.Element => {
  const [methods, setMethods] = useState<DeliveryMethod[] | undefined >([]);
  const { isSystemAdmin, isAdmin } = useRole();
  const dispatch = useDispatch();

  const { handleSubmit, setValue, control, formState: { errors } } = useForm<GlobaConfigurationType>({
    resolver: yupResolver(schema)
  });


  const hasDelivery = useWatch({
    control,
    name: 'hasDelivery',
    defaultValue: configuration.hasDelivery
  });

  useEffect(() => {
    setValue('webSite', configuration.webSite ?? '');
    setValue('globalEmail', configuration.globalEmail ?? '')
    setValue('hasDelivery', configuration.hasDelivery)
    setValue('hasAutoRefill', configuration.hasAutoRefill)
    setValue('userInfoIsRequired', configuration.userInfoIsRequired)
    setValue('facebook', configuration.facebook ?? '')
    setValue('twitter', configuration.twitter ?? '')
    setValue('instagram', configuration.instagram ?? '')
    setMethods(configuration.deliveryMethos?.delibery_methods);
  }, [configuration, setValue]);


  const saveData = (values: GlobaConfigurationType) => {
    regularAlert({
      callback: () => dispatch(
        updatePharmacyConfiguration({
          id: configuration.id,
          webSite: values.webSite,
          globalEmail: values.globalEmail,
          hasDelivery: values.hasDelivery,
          deliveryMethos: methods,
          hasAutoRefill: values.hasAutoRefill,
          userInfoIsRequired: values.userInfoIsRequired,
          facebook: values.facebook,
          twitter: values.twitter,
          instagram: values.instagram
        }),
      ),

      successConfirmation: {
        title: 'Updated!',
        text: 'Pharmacy configuration has been Updated Successfully.',
      }
    });
  }

  const handleDeliveryMethods = (index: number): void => {
    const newMethodsList = methods ?? []; 
    newMethodsList[index].isActive = !newMethodsList[index].isActive;
    setMethods([...newMethodsList]);
  };

  return (
    <>
      <form onSubmit={handleSubmit(saveData)}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Typography variant="h5" gutterBottom style={{
              padding: '2.5% 0px'
            }}> 
              Configuration
              </Typography>
            <Divider variant="fullWidth" component="hr" />
          </Grid>
        </Grid>
        <Grid container spacing={3} className="pharmacy__details">
          <Grid item xs={12} md={6}>
            <div className="input_container">
              <FormInput
                fullWidth
                control={control}
                name="globalEmail"
                label="Secondary Email"
                errors={{
                  error: !!errors.globalEmail,
                  message: errors.globalEmail?.message
                }}
                disabled={!!pharmactHasGlobalconfiguration}
                />
            </div>
            <div className="input_container">
              <FormInput
                fullWidth
                control={control}
                name="facebook"
                label="Facebook Account"
                errors={{
                  error: !!errors.facebook,
                  message: errors.facebook?.message
                }}
                disabled={!!pharmactHasGlobalconfiguration}
                />
            </div>
            <div className="input_container">
              <FormInput
                fullWidth
                control={control}
                name="twitter"
                label="Twitter Account"
                errors={{
                  error: !!errors.twitter,
                  message: errors.twitter?.message
                }}
                disabled={!!pharmactHasGlobalconfiguration}
                />
            </div>
            <div className="input_container">
              <FormCheckbox
                control={control}
                name="hasDelivery"
                label="Has Delivery?"
                errors={{
                  error: !!errors.hasDelivery,
                  message: errors.hasDelivery?.message
                }}
                disabled={!!pharmactHasGlobalconfiguration}
                />
              </div>
           
                {methods && methods.length > 0 && methods.map((method: DeliveryMethod, index: number) => 
                  <FormControlLabel
                  key={index}
                    control={
                      <Checkbox
                        checked={method.isActive}
                        onChange={() => handleDeliveryMethods(index)}
                        name={method.method}
                        color="primary"
                        />
                    }
                    label={method.method}
                    disabled={!!pharmactHasGlobalconfiguration || !hasDelivery}
                    />
                 ) }
           
          </Grid>
          <Grid item xs={12} md={6}>
            <div className="input_container">
              <FormInput
                fullWidth
                control={control}
                name="webSite"
                label="Website"
                errors={{
                  error: !!errors.webSite,
                  message: errors.webSite?.message
                }}
                disabled={!!pharmactHasGlobalconfiguration}
                />
            </div>
            <div className="input_container">
              <FormInput
                fullWidth
                control={control}
                name="instagram"
                label="Instagram Account"
                errors={{
                  error: !!errors.instagram,
                  message: errors.instagram?.message
                }}
                disabled={!!pharmactHasGlobalconfiguration}
                />
            </div>
         
            <div className="input_container">
              <FormCheckbox
                control={control}
                name="hasAutoRefill"
                label="Has Auto Refill?"
                errors={{
                  error: !!errors.hasAutoRefill,
                  message: errors.hasAutoRefill?.message
                }}
                disabled={!!pharmactHasGlobalconfiguration}
                />
              <FormCheckbox
                control={control}
                name="userInfoIsRequired"
                label="User Information Is Required On The Mobile App?"
                errors={{
                  error: !!errors.userInfoIsRequired,
                  message: errors.userInfoIsRequired?.message
                }}
                disabled={!!pharmactHasGlobalconfiguration}
                />
            </div>
          
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} style={{
            display: 'flex'
          }}>
            <div style={{
              marginLeft: 'auto'
            }}>
              {(isSystemAdmin || isAdmin) && !pharmactHasGlobalconfiguration &&
                <ThemeButton
                  text="Save Cogfiguration"
                  clases="tc-btn"
                  color="primary"
                  variant="contained"
                  />}
            </div>

          </Grid>
        </Grid>
      </form>
    </>
  );
}
