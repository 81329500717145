import { useSystemRoles } from './useSystemRoles';

export const useRole = () => {
  const isReader = useSystemRoles('Reader');
  const isAdmin = useSystemRoles('Admin');
  const isSystem = useSystemRoles('SystemAdmin');
  const isOntAdmin = useSystemRoles('OntimeAdmin');

  const isSystemAdmin = isSystem || isOntAdmin;

  return {
    isReader,
    isAdmin,
    isSystemAdmin,
  };
};
