import * as React from 'react';

import { ApiKeyResponse, createNewAPIKey } from '../../services/actions/api-keys';
import {
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  TextField
} from '@material-ui/core';
import {
  DialogTitle,
  ThemeButton,
} from '../../layout/App.components';

import Alert from '@material-ui/lab/Alert';
import { useDispatch } from 'react-redux';
import useFormValidation from '../../hooks/useFormValidation';
import { validateApiKeyData } from '../../util/validation';

interface EditModalProps {
  isOpen: boolean;
  handleClose: () => void;
}

interface ModalStates {
  client: string;
  isActive: boolean;
}

export const CreateApiKeyModal: React.FC<EditModalProps> = ({
  isOpen,
  handleClose,
}: EditModalProps): JSX.Element => {
  const dispatch = useDispatch();
  const [apiKey,setAPIKey] = React.useState<string | null | undefined>(null);
  const [error, setError] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const initialState: ModalStates = {
    client: '',
    isActive: true,
  };

 

  const onSubmit = () => {
    setIsLoading(true);
    cancelSubmit();
    dispatch(
      createNewAPIKey({
        client: values.client,
        isActive: values.isActive && values.isActive !== '' ? values.isActive : false,
      }),
    ).then((entity: ApiKeyResponse) =>  {
      if(entity.action) {
        setAPIKey(entity.response ? entity.response.apiKey : null)
      }
      setIsLoading(false);
      cleanValues();
    
    }).catch((response: ApiKeyResponse) =>  {
      console.log(response);
      setError(true);
      setIsLoading(false)
    });
  };

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    setValues,
    setSubmitting,
    errors,
  } = useFormValidation(initialState, validateApiKeyData, onSubmit);

  const cleanValues = (): void => {
    setValues(initialState);
  };

  const cancelSubmit = (): void => {
    setSubmitting(false);
  };

  const onClose = () => {
    setAPIKey(null);
    setError(false);
    handleClose()
  }
  
  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={isOpen}
        onClose={onClose}
        aria-labelledby="max-width-dialog-title">
        <DialogTitle id="customized-dialog-title" onClose={onClose}>
          Create API Key
        </DialogTitle>
        <DialogContent dividers>
          <div className="edit-form-container"> 
            {apiKey && typeof apiKey !== 'undefined' && apiKey !== '' &&
              <div className="api-key">
              <p>New API Key created and <b>it will be displayed only now</b></p>
              <Alert severity="success"> <b>{apiKey || apiKey}</b></Alert>
              </div>
            }
            {error && 
              <div className="api-key">
                <Alert severity="error">Something happened, please try again latter.</Alert>
              </div>
            }
            <form onSubmit={handleSubmit} method="POST">
              <TextField
                error={errors.client && true}
                id="client"
                label={errors.client ? errors.client : 'API Client'}
                className=""
                name="client"
                autoComplete="client"
                margin="normal"
                fullWidth
                placeholder="client"
                value={values.client}
                onChange={handleChange}
                onBlur={handleBlur}
                InputLabelProps={{
                  shrink: true,
                }}
                />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.isActive}
                    onChange={handleChange}
                    name="isActive"
                    color="primary"
                    />
                }
                label="Active"
                />
             
              <ThemeButton
                isDisabled={isLoading}
                text="Create App Key"
                clases="tc-btn"
                color="primary"
                variant="contained"
                />
            </form>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};
