import * as React from 'react';
import './counting-card.scss';
interface CountingCardProps {
  title: string;
  count?: number;
  children?: any;
}
export const CountingCard: React.FC<CountingCardProps> = ({
  title,
  count,
  children,
}): JSX.Element => {

  return (
    <div className="counting-card high">
      <div className="header">
        <h3 className="title">{title}</h3>
      </div>

      <div className="number-block">
        {(count || count ===  0) && <h1>{count}</h1>}
        {children}
      </div>
    </div>
  );
};
