import {
  APIKeyDTO,
  APIKeyPost,
  GET_KEY_FAIL,
  GET_KEY_SUCCESS,
} from '../types/APIKeys';
import axios, { AxiosResponse } from 'axios';

import { API_HOST } from '../../../config';
import { AnyAction } from 'redux';
import { Dispatch } from 'react';
import { loadUser } from './auth';

export type ApiKeyResponse = {
  action: boolean;
  message: string;
  response?: APIKeyDTO;
};

export const getAPIKeys = (): any => async (
  dispatch: Dispatch<AnyAction>,
): Promise<any> => {
  try {
    const response = await axios.get(
      `${API_HOST}/ontime_admin/pharmacy_api_keys/`,
    );

    dispatch(loadUser());
    dispatch({
      type: GET_KEY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: GET_KEY_FAIL,
    });
  }
};

export const createNewAPIKey = (formData: APIKeyPost): any => async (
  dispatch: Dispatch<AnyAction>,
): Promise<ApiKeyResponse> => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  try {
    const response: AxiosResponse<APIKeyDTO> = await axios.post(
      `${API_HOST}/ontime_admin/pharmacy_api_keys/`,
      formData,
      config,
    );

    console.log(response);
    dispatch(loadUser());
    dispatch(getAPIKeys());
    return {
      action: true,
      message: 'Success',
      response: response.data,
    };
  } catch (error: any) {
    return {
      action: false,
      message:
        error && error.response && error.response.data
          ? error.response.data.message
          : 'Error',
    };
  }
};
