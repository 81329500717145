import {
  DELETE_EMPLOYEES_DETAILS_FAIL,
  DELETE_EMPLOYEES_DETAILS_SUCCESS,
  DELETE_MULTIPLE_EMPLOYEES_ERROR,
  DELETE_MULTIPLE_EMPLOYEES_SUCCESS,
  GET_EMPLOYEES_DETAILS_FAIL,
  GET_EMPLOYEES_DETAILS_SUCCESS,
  GET_EMPLOYEES_FAIL,
  GET_EMPLOYEES_SUCCESS,
  STATUS_EMPLOYEES_DETAILS_FAIL,
  STATUS_EMPLOYEES_DETAILS_SUCCESS,
  UPDATE_EMPLOYEES_DETAILS_FAIL,
  UPDATE_EMPLOYEES_DETAILS_SUCCESS,
} from '../types/Employees';

import { API_HOST } from '../../../config';
import { AnyAction } from 'redux';
import { Dispatch } from 'react';
import { SET_USER_SEARCH } from '../types/Search';
import axios from 'axios';
import { loadUser } from './auth';

export const getEmployees = (): any => async (
  dispatch: Dispatch<AnyAction>,
): Promise<void> => {
  try {
    const response = await axios.get(`${API_HOST}/ontime_admin/users`);

    dispatch({
      type: GET_EMPLOYEES_SUCCESS,
      payload: response.data,
    });

    dispatch({
      type: SET_USER_SEARCH,
      payload: response.data,
    });

    dispatch(loadUser());
  } catch (error) {
    dispatch({
      type: GET_EMPLOYEES_FAIL,
    });
  }
};

export const getEmployeesDetails = (id: string): any => async (
  dispatch: Dispatch<AnyAction>,
): Promise<void> => {
  try {
    const response = await axios.get(`${API_HOST}/ontime_admin/users/${id}`);

    dispatch({
      type: GET_EMPLOYEES_DETAILS_SUCCESS,
      payload: response.data,
    });

    dispatch(loadUser());
  } catch (error) {
    dispatch({
      type: GET_EMPLOYEES_DETAILS_FAIL,
    });
  }
};

export const deleteEmployees = (id: string): any => async (
  dispatch: Dispatch<AnyAction>,
): Promise<any> => {
  try {
    const response = await axios.delete(`${API_HOST}/ontime_admin/users/${id}`);

    dispatch({
      type: DELETE_EMPLOYEES_DETAILS_SUCCESS,
      payload: response.data,
    });

    dispatch(loadUser());
    return {
      action: true,
      message: '',
    };
  } catch (error: any) {
    dispatch({
      type: DELETE_EMPLOYEES_DETAILS_FAIL,
    });

    return {
      action: false,
      message:
        error.response && error.response.data
          ? error.response.data.message
          : '',
    };
  }
};

export const updateEmployees = (id: any, data: any): any => async (
  dispatch: Dispatch<AnyAction>,
): Promise<any> => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify(data);

  try {
    const response = await axios.put(
      `${API_HOST}/ontime_admin/users/update/${id}`,
      body,
      config,
    );

    dispatch({
      type: UPDATE_EMPLOYEES_DETAILS_SUCCESS,
      payload: response.data,
    });
    dispatch(getEmployees());
    dispatch(loadUser());
    return {
      action: true,
      message: '',
    };
  } catch (error: any) {
    dispatch({
      type: UPDATE_EMPLOYEES_DETAILS_FAIL,
    });

    return {
      action: false,
      message:
        error.response && error.response.data
          ? error.response.data.message
          : '',
    };
  }
};

export const statusEmployees = (id: any, data: any): any => async (
  dispatch: Dispatch<AnyAction>,
): Promise<any> => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify(data);

  try {
    const response = await axios.put(
      `${API_HOST}/ontime_admin/users/status/${id}`,
      body,
      config,
    );

    dispatch({
      type: STATUS_EMPLOYEES_DETAILS_SUCCESS,
      payload: response.data,
    });

    dispatch(getEmployees());
    dispatch(loadUser());
    return {
      action: true,
      message: '',
    };
  } catch (error: any) {
    dispatch({
      type: STATUS_EMPLOYEES_DETAILS_FAIL,
    });

    return {
      action: false,
      message:
        error.response && error.response.data
          ? error.response.data.message
          : '',
    };
  }
};

export const createEmployees = (data: any): any => async (
  dispatch: Dispatch<AnyAction>,
): Promise<any> => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify(data);

  try {
    await axios.post(`${API_HOST}/ontime_admin/users/create`, body, config);

    dispatch(getEmployees());
    dispatch(loadUser());
    return {
      action: true,
      message: '',
    };
  } catch (error: any) {
    return {
      action: false,
      message:
        error.response && error.response.data
          ? error.response.data.message
          : '',
    };
  }
};

export const deleteMultipleEmployees = (ids: string[]): any => async (
  dispatch: Dispatch<AnyAction>,
): Promise<any> => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      ids,
    },
  };

  try {
    const response = await axios.delete(
      `${API_HOST}/ontime_admin/users/delete/multiple`,
      config,
    );

    dispatch({
      type: DELETE_MULTIPLE_EMPLOYEES_SUCCESS,
      payload: response.data,
    });

    dispatch(getEmployees());
    dispatch(loadUser());
    return {
      action: true,
      message: '',
    };
  } catch (error: any) {
    console.log(error.response);
    dispatch({
      type: DELETE_MULTIPLE_EMPLOYEES_ERROR,
    });
    return {
      action: false,
      message:
        error.response && error.response.data
          ? error.response.data.message
          : '',
    };
  }
};
