import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import {
  Breadcrumbs,
  Typography,
  Card,
  Grid,
  CardContent,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import './user-details.scss';
import {
  deleteEmployees,
  getEmployees,
  statusEmployees,
} from '../../services/actions/employees';
import { useDispatch, useSelector } from 'react-redux';
import { Loading, ThemeButton } from '../../layout/App.components';
import { confirmationAlert } from '../../util/systemAlert';
import { UserEditModal } from './UserEditModal';
import { useRole } from '../../hooks/useRole';

export const UserDetailsPage: React.FC<RouteComponentProps<{}>> = (
  props: RouteComponentProps<{}> | any,
): JSX.Element => {
  const { id } = props.match.params;
  // useSelector(state => state.todos[props.id])
  const { user, loading } = useSelector((state: any) => ({
    user: state.employees.users.find((user: any) => user.id === id),
    loading: state.employees.loading,
  }));
  const dispatch = useDispatch();
  const {isSystemAdmin, isReader} = useRole();

  const [open, setOpen] = React.useState(false);

  const handleClose = (): void => {
    setOpen(false);
  };

  React.useEffect(() => {
    //dispatch(getEmployeesDetails(id));
    dispatch(getEmployees());
  }, [dispatch, id]);

  const handleStatus = () => {
    const status = {
      isActive: !user.isActive,
    };

    const statusText = !user.isActive ? 'Active' : 'Archive';

    confirmationAlert({
      title: `Are you sure you want to ${statusText} this employee?`,
      text: '',
      confirmButtonText: 'Yes',
      successConfirmation: {
        title: 'Status Change',
        text: 'Employee status has been change.',
      },
      callback: () => dispatch(statusEmployees(id, status)),
    });
  };
  const onDelete = () => {
    confirmationAlert({
      title: 'Are you sure you want to delete this employee?',
      text: "This change is permanent!",
      confirmButtonText: 'Yes, delete it!',
      successConfirmation: {
        title: 'Deleted!',
        text: 'Employee has been deleted.',
      },
      callback: () => dispatch(deleteEmployees(id)),
      redirect: () => window.location.replace('/dashboard/user-list'),
    });
  };

  return (
    <section className="user-details-page">
      {loading && user === undefined ? (
        <Loading />
      ) : (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Breadcrumbs aria-label="breadcrumb">
                  <Link to="/dashboard/user-list">User List</Link>
                <Typography color="textPrimary">User details</Typography>
              </Breadcrumbs>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Card className={'contact-info'}>
                <CardContent>
                  <div className="header">
                    <span className="name">
                      {user.firstName} {user.lastName}
                    </span>
                    <span className="role">{user.role} </span>

                    <span
                      className={`ont-badge ${
                        user.isActive ? 'active' : 'archived'
                      }`}>
                      {user.isActive ? 'Active' : 'Archived'}
                    </span>
                  </div>

                  <div className="detail-btn" onClick={() => setOpen(true)}>
                    <ThemeButton
                      text="Edit User"
                      clases="tc-btn"
                      color="primary"
                      variant="contained"
                    />
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={8}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Card className={'contact-info'}>
                    <CardContent>
                      <div className="action-container">
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={6}>
                            {user.isActive && !isReader ? (
                              <div
                                className="action-btn archive"
                                onClick={handleStatus}>
                                Archive User
                              </div>
                            ) : (
                              <div
                                className="action-btn active"
                                onClick={handleStatus}>
                                Active User
                              </div>
                            )}
                          </Grid>
                          <Grid item xs={12} md={6}>
                            {isSystemAdmin && (
                              <div
                                className="action-btn delete"
                                onClick={onDelete}>
                                Delete User
                              </div>
                            )}
                          </Grid>
                        </Grid>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Card className={'contact-info'}>
                    <CardContent>
                      <div className="user-info">
                        <div className="contact-header">
                          <span>Contact Information</span>
                        </div>
                        <div className="info-container">
                          <div className="info">
                            <span className="label">Email</span>
                            <span className="info-value">{user.email}</span>
                          </div>
                          <div className="info">
                            <span className="label">Phone Number</span>
                            <span className="info-value">
                              {user.phoneNumber}
                            </span>
                          </div>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
                  <Grid item xs={12} md={6}>
                  <Card className={'contact-info'}>
                    <CardContent>test</CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <UserEditModal
            isOpen={open}
            handleClose={handleClose}
            user={user}
            id={id}
          />
        </>
      )}
    </section>
  );
};
