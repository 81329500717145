import * as React from 'react';
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import { IconButton, Theme } from '@material-ui/core';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexShrink: 0,
      color: theme.palette.text.secondary,
      marginLeft: theme.spacing(2.5),
    },
  }),
);

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onChangePage: (
    _event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    newPage: number,
  ) => void;
}

export const TablePaginationActions = (
  props: TablePaginationActionsProps,
): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (
    _event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ): void => {
    onChangePage(_event, 0);
  };

  const handleBackButtonClick = (
    _event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ): void => {
    onChangePage(_event, page - 1);
  };

  const handleNextButtonClick = (
    _event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ): void => {
    onChangePage(_event, page + 1);
  };

  const handleLastPageButtonClick = (
    _event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ): void => {
    onChangePage(_event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page">
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page">
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page">
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page">
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
};
