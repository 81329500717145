import * as React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { TableRow, TableHead, TableCell } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { myStyles } from './tableStyles';
import { BlueCheckbox } from '../checkbox/Checkbox';
import { useRole } from '../../../hooks/useRole';

const useStyles = makeStyles(() => createStyles(myStyles));

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  numSelected: number;
  onSelectAllClick: (
    event: React.ChangeEvent<HTMLInputElement | any>,
    checked: boolean,
  ) => void;
  rowCount: number;
  tabaleHeader: string[];
  onHandleMultipleDelete?: () => void;
  isActiveDelete?: boolean;
}

// remember move this to another file
export const EnhancedTableHead: React.FC<EnhancedTableProps> = (
  props: EnhancedTableProps,
): JSX.Element => {
  const {
    classes,
    onSelectAllClick,
    numSelected,
    rowCount,
    tabaleHeader,
    onHandleMultipleDelete,
    isActiveDelete = true
  } = props;
  const {isSystemAdmin} = useRole();

  const IsActiveElement = (isSystemAdmin && isActiveDelete)
  return (
    <TableHead className={classes.thead}>
      <TableRow className={classes.tr}>
        {IsActiveElement && (
        <TableCell className={classes.matCheck}>
         
            <BlueCheckbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'select all desserts' }}
              color="primary"
            />
        
        </TableCell>
        )}
        {numSelected > 0 ? (
          
          <TableCell className={classes.thCel} align="right">
            {isActiveDelete && (
              <span onClick={onHandleMultipleDelete}>
                {numSelected} selected <DeleteIcon className={classes.matIcon} />
              </span>
            )}
          </TableCell>
        ) : (
          tabaleHeader.map((name: string, index: number) => (
            <TableCell className={classes.thCel} align="right" key={index}>
              {name}
            </TableCell>
          ))
        )}
      </TableRow>
    </TableHead>
  );
};
