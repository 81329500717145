import { EmployActionType } from '../types/actions';
import {
  GET_EMPLOYEES_SUCCESS,
  GET_EMPLOYEES_FAIL,
  EmployeesStates,
  GET_EMPLOYEES_DETAILS_SUCCESS,
  GET_EMPLOYEES_DETAILS_FAIL,
} from '../types/Employees';

const initialState: EmployeesStates = {
  loading: true,
  users: [],
  user: [],
};

export const employeesReducer = (
  state = initialState,
  action: EmployActionType,
): EmployeesStates => {
  const { type, payload } = action;

  switch (type) {
    case GET_EMPLOYEES_SUCCESS:
      return {
        ...state,
        loading: false,
        users: payload,
        user: [],
      };
    case GET_EMPLOYEES_FAIL:
      return {
        ...state,
        loading: false,
        users: [],
      };
    case GET_EMPLOYEES_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        user: payload,
      };
    case GET_EMPLOYEES_DETAILS_FAIL:
      return {
        ...state,
        loading: true,
        user: [],
      };
    default:
      return state;
  }
};
