import React from 'react'
import { Link } from 'react-router-dom';
import './password-reset-page.scss';
import {
  Paper,
  Typography,
  makeStyles,
  Theme,
  createStyles,
  Container
} from '@material-ui/core';
import { ErrorAlert, ThemeButton, EmailInput } from '../../layout/App.components';
import { useDispatch } from 'react-redux';
import useFormValidation from '../../hooks/useFormValidation';
import { validateAuth } from '../../util/validation';
import { resetPassword } from '../../services/actions/user';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      alignContent: 'center',
      height: '100vh',
    },
    container: {
      flex: '1',
      padding: theme.spacing(5, 3),
      maxWidth: '90%',
      boxShadow: '0 6px 18px rgba(0, 0, 0, 0.06)',
    },
    textField: {}
  }),
);

export const PasswordResetPage: React.FC = (): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const state = { email: '' };
  // const [reset,setReset] = React.useState(false);

  const onSubmit = async () => {
    const request = await dispatch(resetPassword(values.email));

    if (request.action) {
      // Here we let know the user the password was sent successfully
      // We only handle success logic because we handle the error with <ErrorAlert/>
    }

    setSubmitting(false)
  };

  const { handleSubmit, handleChange, errors, handleBlur, values, setSubmitting } = useFormValidation(
    state,
    (val: any) => validateAuth(val, false),
    onSubmit
  );

  return (
    <Container maxWidth="sm">
      <div className="password-reset-page">
        <div className={classes.root}>
          <Paper className={classes.container}>
            <ErrorAlert />
            <Typography variant="h5" component="h3">
              Reset Password
          </Typography>
            <Typography component="p">Enter your user email</Typography>
            <form onSubmit={handleSubmit} method="POST">

              <EmailInput
                error={errors.email && true}
                label={errors.email ? errors.email : 'Email'}
                value={values.email}
                variant="outlined"
                handleChange={handleChange}
                className={classes.textField}
                onBlur={handleBlur}
              />   

              <ThemeButton
                text="Reset My Password"
                clases="tc-btn"
                color="primary"
                variant="contained"
              />
            </form>
            <Link className="login-link" to="/login">
              Back to Login
        </Link>
          </Paper>
        </div>
      </div>
    </Container>
  );
}
