export const capital_letter = (str: string): string =>
  str
    ? str
        .toLocaleLowerCase()
        .replace(/\b[a-zA-Z]/g, (match: string) => match.toUpperCase())
    : str;



interface DeliveryMethod {
  refill_rx_integer: number;
  ontime_api_integer: 0 | 1 | 2 | 3;
  method: string;
  isActive?: boolean;
}

export const deliveryMethods: DeliveryMethod[] = [
  {
    refill_rx_integer: 0,
    ontime_api_integer: 0,
    method: 'Recoger en farmacia',
  },
  {
    refill_rx_integer: 1,
    ontime_api_integer: 1,
    method: 'Entrega al hogar',
  },
  {
    refill_rx_integer: 4,
    ontime_api_integer: 2,
    method: 'Envio por correo',
  },
  {
    refill_rx_integer: 1,
    ontime_api_integer: 3,
    method: 'Entrega a hogar de ancianos',
  },
];
