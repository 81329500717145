import React from 'react'
import { makeStyles, Theme, createStyles, InputBase } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { useDispatch, useSelector } from 'react-redux';
import { getPharmacist, addPharmacistPharmacy } from '../../services/actions/pharmacist';
import { reduxState } from '../../services/types/reduxStates';
import { Loading } from '../../layout/App.components';
import { Pharmacist } from '../../services/types/Pharmacist';
import { regularAlert } from '../../util/systemAlert';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    searchContainer: {

    },
    search: {
      position: 'relative',
      borderRadius: 15,
      backgroundColor: '#F5F6F8',
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
      },
    },
    searchIcon: {
      width: theme.spacing(7),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(2, 1, 2, 7),
      transition: theme.transitions.create('width'),
    }
  }),
);

interface AddPharmacistProps {
  pharmacistList: Pharmacist[];
  pharmacyId: string;
  handleClose: () => void;
}

export const AddPharmacistFromList: React.FC<AddPharmacistProps> = ({
  pharmacistList,
  pharmacyId,
  handleClose
}): JSX.Element => {
  const { pharmacists, loading } = useSelector((state: reduxState) => state.pharmacists);
  const [search, setSearch] = React.useState('');
  const [listOfPharmacists, setListOfPharmacists] = React.useState<null | Pharmacist[]>(null);
  const [filteredPharmacistsList, setFilteredPharmacistsList] = React.useState<null | Pharmacist[]>(null);


  const classes = useStyles();
  const dispatch = useDispatch();

  const loadList = React.useCallback(() => {
    if (!listOfPharmacists) {
      dispatch(getPharmacist());
      if (pharmacists) {
   
        let newList = [];
        if (pharmacistList.length){
          const filterPharmacistList = pharmacistList.reduce((list: any, pharmacist: Pharmacist) => { list[pharmacist.id] = pharmacist; return list; }, {});
          newList = pharmacists.filter((pharmacist: Pharmacist) => !filterPharmacistList[pharmacist.id]);
        } else {
          newList = pharmacists;
        }
   
        setListOfPharmacists(newList);
        setFilteredPharmacistsList(newList);
      }
    }
  }, [listOfPharmacists, pharmacists, dispatch, pharmacistList]);

  React.useEffect(() => {
    loadList();
  }, [loadList])

  const updateSearch = (_event: any): void => {
    const search = _event.target.value;
    setSearch(search);
    const filter = search.toLowerCase();

    const searchBy = (options: { name: string; email: string }) => {
      const hasName = options.name.toLowerCase().includes(filter);
      const hasEmail = options.email.toLowerCase().includes(filter);
      let result: boolean = false;

      if (hasName) {
        result = hasName;
      } else if (hasEmail) {
        result = hasEmail;
      }

      return result;
    };

    const updatedList = filteredPharmacistsList && filteredPharmacistsList.filter((pharmacist: Pharmacist): boolean =>
      searchBy({
        name: `${pharmacist.firstName} ${pharmacist.lastName}`,
        email: pharmacist.email,
      }),
    );

    setListOfPharmacists(updatedList);
  };

  const AddPharmacist = (pharmacistId: string) => {

    regularAlert({
      callback: async () => await dispatch(addPharmacistPharmacy({
        id: pharmacistId,
        pharmacyId
      })),
      successConfirmation: {
        title: 'Added!',
        text: 'New pharmacist has been added.',
      },
      redirect: () => {
        dispatch(getPharmacist());
        handleClose();
      }
    });
  }

  return (
    <>
      {loading || !pharmacists ? (
        <Loading />
      ) : (
          <div>

            <div className={classes.search}>
              <div className={classes.searchContainer}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  placeholder="Search…"
                  fullWidth
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  onChange={updateSearch}
                  value={search}
                  inputProps={{ 'aria-label': 'search' }}
                />
              </div>

            </div>

            <div className="pharmacy_list_container">
              {listOfPharmacists && listOfPharmacists.length ? (
                listOfPharmacists.map((pharmacist: Pharmacist) => (
                  <div className="pharmacies_list" key={pharmacist.id}>
                    <div style={{ marginRight: 'auto' }}>
                      <h5 style={{ marginBottom: 5, marginTop: 0 }}>
                        {pharmacist.firstName} {pharmacist.lastName}
                      </h5>
                      <p>{pharmacist.email}</p>
                    </div>
                    <div style={{ marginLeft: 'auto' }}>
                      <span
                        className="add_btn"
                        onClick={() => AddPharmacist(pharmacist.id)}
                      >Add
                      </span>
                    </div>
                  </div>
                ))

              ) : (
                  <>
                    <h5 style={{ textAlign: 'center', marginTop: 35 }}>Sorry, no record found</h5>
                  </>
                )}
            </div>
          </div>
        )}
    </>
  )
}
