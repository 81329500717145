import { ERROR_SET_REPORT, GET_REPORTS_ERROR, GET_REPORTS_SUCCESS, SET_REPORT } from '../types/report';

import { API_HOST } from '../../../config';
import { AnyAction } from 'redux';
import { Dispatch } from 'react';
import axios from 'axios';

export const getReports = (): any => async (
  dispatch: Dispatch<AnyAction>,
): Promise<any> => {
  try {
    const response = await axios.get(`${API_HOST}/ontime_admin/reports`);

    dispatch({
      type: GET_REPORTS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: GET_REPORTS_ERROR,
    });
  }
};

export const pharmacyReports= (pharmacyID: string): any => async (dispatch: Dispatch<AnyAction>): Promise<any> => {
  
  try {
    const response = await axios.get(
      `${API_HOST}/pharmacy_admin/pharmacy/report/${pharmacyID}`);

    dispatch({
      type: SET_REPORT,
      payload: response.data,
    });

  } catch (error) {
    dispatch({
      type: ERROR_SET_REPORT,
    });

    throw error;
  }
};
