import { FunctionComponent } from 'react';
import {
  PageSettings,
  DashboardPage,
  UserListPage,
  UserDetailsPage,
  PharmaciesListPage,
  PharmacyDetailsPage,
  PageUserProfile,
  PharmacistsListPage,
  PharmacistDetailsPage,
  ChainsListPage,
  APIGoupListPage,
} from '../pages/App.pages';

interface RoutesType {
  path: string;
  isPrtotected?: boolean;
  isExact?: boolean;
  component: FunctionComponent<{}> | any;
}

export const routes: Array<RoutesType> = [
  {
    path: '/dashboard',
    isPrtotected: true,
    component: DashboardPage,
  },
  {
    path: '/dashboard/settings',
    isPrtotected: true,
    component: PageSettings,
  },
  {
    path: '/dashboard/user-list',
    isPrtotected: true,
    component: UserListPage,
  },
  {
    path: '/dashboard/user-details/:id',
    isPrtotected: true,
    component: UserDetailsPage,
  },
  {
    path: '/dashboard/pharmacies-list',
    isPrtotected: true,
    component: PharmaciesListPage,
  },
  {
    path: '/dashboard/pharmacy-details/:id',
    isPrtotected: true,
    component: PharmacyDetailsPage,
  },
  {
    path: '/dashboard/pharmacy-details/:id/pharmacists',
    isPrtotected: true,
    component: PharmacistsListPage,
  },
  {
    path: '/dashboard/profile/',
    isPrtotected: true,
    component: PageUserProfile,
  },
  {
    path: '/dashboard/pharmacist-details/:id/:pharmacyID',
    isPrtotected: true,
    component: PharmacistDetailsPage,
  },
  {
    path: '/dashboard/chain-list',
    isPrtotected: true,
    component: ChainsListPage,
  },
  {
    path: '/dashboard/generate_api_key_group',
    isPrtotected: true,
    component: APIGoupListPage,
  },
];
