import React from 'react'
import { useDispatch } from 'react-redux';
import { regularAlert } from '../../util/systemAlert';
import { createPharmacists } from '../../services/actions/pharmacist';
import useFormValidation from '../../hooks/useFormValidation';
import { validateUserData } from '../../util/validation';
import { TextField, InputLabel, Select, MenuItem } from '@material-ui/core';
import { EmailInput, PhoneNumberInput, ThemeButton } from '../../layout/App.components';
import { capital_letter } from '../../util/helpers';

interface NewPharmacistProps {
  handleClose: () => void;
  pharmacyId: string;
}
interface ModalStates {
  firstName: string | any;
  lastName: string | any;
  email: string | any;
  phoneNumber: string | any;
  role: string | any;
}

export const AddNewPharmacist: React.FC<NewPharmacistProps> = ({ 
  handleClose,
  pharmacyId,
}): JSX.Element => {
  const dispatch = useDispatch();
  const initialState: ModalStates = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    role: 'Pharmacist',
  };

  const onSubmit = () => {
    regularAlert({
      callback: () => {
        cancelSubmit();
        return dispatch(
          createPharmacists({
            firstName: capital_letter(values.firstName),
            lastName: capital_letter(values.lastName),
            email: values.email.toLocaleLowerCase(),
            phoneNumber: values.phoneNumber,
            role: values.role,
            pharmacy: pharmacyId
          }),
        );
      },
      successConfirmation: {
        title: 'Created!',
        text: 'Pharmacy has been added successfully.',
      },
      redirect: () => {
        cleanValues();
        handleClose();
      },
      callbackIfError: () => cancelSubmit(),
    });
  };

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    setValues,
    isSubmitting,
    setSubmitting,
    errors,
  } = useFormValidation(initialState, validateUserData, onSubmit);

  const cleanValues = (): void => {
    setValues(initialState);
  };

  const cancelSubmit = (): void => {
    setSubmitting(false);
  };
  return (
    <>
      <form onSubmit={handleSubmit} method="POST">
        <TextField
          error={errors.firstName && true}
          id="firstName"
          label={errors.firstName ? errors.firstName : 'First Name'}
          className=""
          name="firstName"
          autoComplete="firstName"
          margin="normal"
          fullWidth
          value={values.firstName}
          placeholder="First Name"
          onChange={handleChange}
          onBlur={handleBlur}
          InputLabelProps={{
            shrink: true,
          }}
          />
        <TextField
          error={errors.lastName && true}
          id="lastName"
          label={errors.lastName ? errors.lastName : 'Last Name'}
          className=""
          name="lastName"
          autoComplete="lastName"
          margin="normal"
          fullWidth
          placeholder="Last Name"
          value={values.lastName}
          onChange={handleChange}
          onBlur={handleBlur}
          InputLabelProps={{
            shrink: true,
          }}
          />
        <EmailInput
          error={errors.email && true}
          label={errors.email ? errors.email : 'Email'}
          value={values.email}
          handleChange={handleChange}
          />

        <PhoneNumberInput
          label={errors.phoneNumber ? errors.phoneNumber : 'Phone Number'}
          error={errors.phoneNumber && true}
          id="phoneNumber"
          className=""
          name="phoneNumber"
          autoComplete="phoneNumber "
          placeholder="Phone Number"
          fullWidth
          value={values.phoneNumber}
          onChange={handleChange}
          onBlur={handleBlur}
          />

        <div className="input-container">
          <InputLabel htmlFor="Role">
            {errors.role ? errors.role : 'Role'}
          </InputLabel>
          <Select
            value={values.role}
            onChange={handleChange}
            fullWidth
            inputProps={{
              name: 'role',
              id: 'role',
            }}>
            <MenuItem value="Pharmacist">Pharmacist</MenuItem>
            <MenuItem value="Administrator">Administrator</MenuItem>
            <MenuItem value="Owner">Owner</MenuItem>
            <MenuItem value="ChiefPharmacist">ChiefPharmacist</MenuItem>
            <MenuItem value="PharmacyTechnician">PharmacyTechnician</MenuItem>
          </Select>
        </div>
        <ThemeButton
          isDisabled={isSubmitting}
          text="Add Employee"
          clases="tc-btn"
          color="primary"
          variant="contained"
          />
      </form>
    </>
  )
}
