import * as React from 'react';
import {
  Loading,
  ThemeButton,
  DataTable,
} from '../../layout/App.components';
import { useDispatch, useSelector } from 'react-redux';
import './chains-list.scss';
import { CreateChainModal } from './CreateChainModal';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { useRole } from '../../hooks/useRole';
import { TableOption } from '../../layout/ui/data-table/DataTable';
import { reduxState } from '../../services/types/reduxStates';
import { getChains } from '../../services/actions/pharmacy';
import { PharmacyChain } from '../../services/types/chain';
import { createChainData, Data } from '../../interfaces/chainData';
import { EditChainModal } from './EditChainModal';


export const ChainsListPage: React.FC = (): JSX.Element => {
  const { chains, loading }: { chains: PharmacyChain[]; loading: boolean} = useSelector((state: reduxState) => state.chains);
  const { isSystemAdmin, isAdmin } = useRole();
  const dispatch = useDispatch();

  // modal create
  const [open, setOpen] = React.useState(false);
  const handleClose = (): void => {
    setOpen(false);
  };

  const [chainEditeData, setChainEditeData] = React.useState<PharmacyChain | null>(null)
  const [editIsOpen, setEditIsOpen] = React.useState(false);
  const handleEditeClose = (): void => {
    setEditIsOpen(false);
  };

  const handleEdite = (data: PharmacyChain): void => {
   setChainEditeData(data);
    setEditIsOpen(true);
  };

  React.useEffect(() => {
    dispatch(getChains());
  }, [dispatch]);


  let rows: Data[] | [] = [];

  if (!loading && chains) {
    rows = chains
      .map((chain: PharmacyChain) =>
        createChainData(
          chain.id,
          chain.logo,
          chain.name,
          chain.description,
        ),
      );
  }

  const tableHeader:string[] = [
    'Name',
    'Description',
    'Edit',
  ];

  const tableOption: TableOption<string[], Data[]> = {
    tableHeader,
    tableData: rows,
  };

  return (
    <>
      {loading && !chains ? 
        <Loading />
       : 
        <>
          <section className="list-page-header">
            <div className="create-btn" onClick={() => setOpen(true)}>
              <ThemeButton
                text="Add new Chain"
                clases="tc-btn"
                color="primary"
                variant="contained"
                />
            </div>
          </section>

            <CreateChainModal isOpen={open} handleClose={handleClose} />
            {editIsOpen && 
              <EditChainModal
                chain={chainEditeData}
                isOpen={editIsOpen}
                handleClose={handleEditeClose}
                />
            }
           

          <DataTable
              isActiveDelete={false}
            tableData={tableOption}
            generateCell={(row,column) => {
              switch(column){
                case 'name': return row.name;
                case 'description': return row.description;
                case 'edit':{
                  return (
                    <div className="edit-wrap" onClick={() => handleEdite(row)}>
                     
                    <div className="edit-text">
                      {isSystemAdmin || isAdmin ? 'Edit' : 'View'}
                    </div>
                    <div className="icon">
                      <EditOutlinedIcon />
                    </div>
                    </div>
                  )
                }
                default:
                  return null
              }
            }}
            />
        </>
      }
    </>
  );
};
