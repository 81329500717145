import * as React from 'react';
import {
  Dialog,
  TextField,
  DialogContent,
  Select,
  MenuItem,
  InputLabel,
  Theme,
  createStyles,
  makeStyles,
  Input,
} from '@material-ui/core';
import useFormValidation from '../../hooks/useFormValidation';
import { validatePharmacyData } from '../../util/validation';
import {
  ThemeButton,
  PhoneNumberInput,
  DialogTitle,
  TownInput,
  StateInput,
  EmailInput,
} from '../../layout/App.components';
import { useDispatch, useSelector } from 'react-redux';
import { regularAlert } from '../../util/systemAlert';
import { updatePharmacy, getChains } from '../../services/actions/pharmacy';
import { Pharmacy } from '../../services/types/Pharmacy';
import { reduxState } from '../../services/types/reduxStates';
import { PharmacyChain } from '../../services/types/chain';
import { capital_letter } from '../../util/helpers';
import { APIKeyDTO, APIKeysStates } from '../../services/types/APIKeys';
import { getAPIKeys } from '../../services/actions/api-keys';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingTop: '10px'
    },
    textField: {
      width: '100%',
    },
    centerTextField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: '100%',
    },
    firstTextField: {
      marginRight: theme.spacing(1),
      width: '100%',
    },
  }),
);

interface ModalProps {
  isOpen: boolean;
  pharmacy: Pharmacy;
  handleClose: () => void;
  id: string;
}

interface ModalStates {
  name: string;
  address: string;
  town: string;
  state: string;
  zipCode: string;
  longitude?: string;
  latitude?: string;
  phoneNumber: string;
  faxNumber?: string;
  email: string;
  hasRx30: string;
  rx30Number: string | null;
  hasDelivery: string;
  chain: string;
  apiKey: string
}

export const PharmacyEditModal: React.FC<ModalProps> = ({
  isOpen,
  handleClose,
  pharmacy,
}: ModalProps): JSX.Element => {
  const { chains, loading }: { chains: PharmacyChain[], loading: boolean} = useSelector((state: reduxState) => state.chains);
  const { apiKeys, loading: keyloading }: APIKeysStates = useSelector((state: reduxState) => state.apiKeys);
  const classes = useStyles();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getChains());
    dispatch(getAPIKeys());
  }, [dispatch])

  const initialState: ModalStates = {
    name: pharmacy.name,
    address: pharmacy.address,
    town: pharmacy.town,
    state: pharmacy.state,
    zipCode: pharmacy.zipCode,
    longitude: pharmacy.longitude || '',
    latitude: pharmacy.latitude || '',
    phoneNumber: pharmacy.phoneNumber,
    faxNumber: pharmacy.faxNumber || '',
    email: pharmacy.email,
    hasRx30: pharmacy.hasRx30 ? 'YES' : 'NO',
    rx30Number: pharmacy.rx30Number ? pharmacy.rx30Number : '',
    hasDelivery: pharmacy.hasDelivery ? 'YES' : 'NO',
    chain: pharmacy.chain ? pharmacy.chain.id : '',
    apiKey: pharmacy.apiKey ? pharmacy.apiKey : ''
  };

  const onSubmit = () => {
    regularAlert({
      callback: () => {
        cancelSubmit();

        return dispatch(
          updatePharmacy(pharmacy.id, {
            name: capital_letter(values.name),
            address: values.address,
            town: values.town,
            state: values.state,
            zipCode: values.zipCode,
            longitude: values.longitude,
            latitude: values.latitude,
            phoneNumber: values.phoneNumber,
            faxNumber: values.faxNumber,
            email: values.email.toLocaleLowerCase(),
            hasRx30: values.hasRx30 === 'YES',
            rx30Number: values.hasRx30 === 'NO' && values.rx30Number ? null : values.rx30Number,
            hasDelivery: values.hasDelivery === 'YES',
            chain: values.chain || null,
            apiKey: values.apiKey || null
          }),
        );
      },

      successConfirmation: {
        title: 'Updated!',
        text: 'Pharmacy has been Updated Successfully.',
      },
      redirect: () => handleClose(),
      callbackIfError: () => cancelSubmit(),
    });
  };

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    isSubmitting,
    setSubmitting,
    errors,
  } = useFormValidation(initialState, validatePharmacyData, onSubmit);

  const resetRx30 = React.useCallback(() => {
    if (values.hasRx30 === 'NO' && values.rx30Number) {
      values.rx30Number = null;
    }
  }, [values.rx30Number, values.hasRx30])

  React.useEffect(() => {
    resetRx30()
  }, [resetRx30]);

  const cancelSubmit = (): void => {
    setSubmitting(false);
  };

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title">
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Edit {pharmacy.name}
        </DialogTitle>
        <DialogContent dividers>
          <div className="edit-form-container">
            <form onSubmit={handleSubmit} method="POST">
              <TextField
                error={errors.name && true}
                id="name"
                label={errors.name ? errors.name : 'Pharmacy Name'}
                className=""
                name="name"
                autoComplete="name"
                margin="normal"
                fullWidth
                value={values.name}
                placeholder="Pharmacy Name"
                onChange={handleChange}
                onBlur={handleBlur}
                InputLabelProps={{
                  shrink: true,
                }}
                />

              <TextField
                error={errors.address && true}
                id="address"
                label={errors.address ? errors.address : 'Address'}
                className=""
                name="address"
                autoComplete="address"
                margin="normal"
                fullWidth
                value={values.address}
                placeholder="Pharmacy Address"
                onChange={handleChange}
                onBlur={handleBlur}
                InputLabelProps={{
                  shrink: true,
                }}
                />

              <div className={classes.container}>

                <div className={classes.textField}>
                  <StateInput
                    value={values.state}
                    error={errors.state && true}
                    label={errors.state ? errors.state : 'State'}
                    handleChange={handleChange}
                    />
                </div>
                <div className={classes.centerTextField}>
                  <TownInput
                    value={values.town}
                    error={errors.town && true}
                    label={errors.town ? errors.town : 'City'}
                    handleChange={handleChange}
                    />
                </div>
                <div className={classes.textField}>
                  <InputLabel error={errors.zipCode && true} htmlFor="zipCode">
                    {errors.zipCode ? errors.zipCode : 'Zip Code'}
                  </InputLabel>
                  <Input
                    error={errors.zipCode && true}
                    id="zipCode"
                    name="zipCode"
                    value={values.zipCode}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    />
                </div>
              </div>
              <div className={classes.container}>
                <TextField
                  id="latitude"
                  label="Latitude"
                  name="latitude"
                  className={classes.textField}
                  value={values.latitude}
                  margin="normal"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  />
                <TextField
                  id="longitude"
                  label="Longitude"
                  name="longitude"
                  className={classes.firstTextField}
                  value={values.longitude}
                  margin="normal"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  />
              
              </div>
              <EmailInput
                error={errors.email && true}
                label={errors.email ? errors.email : 'Email'}
                value={values.email}
                handleChange={handleChange}
                />

              <PhoneNumberInput
                label={
                  errors.phoneNumber ? errors.phoneNumber : 'Phone Number '
                }
                error={errors.phoneNumber && true}
                id="phoneNumber"
                className=""
                name="phoneNumber"
                autoComplete="phoneNumber"
                fullWidth
                value={values.phoneNumber}
                onChange={handleChange}
                onBlur={handleBlur}
                />

              <PhoneNumberInput
                label="Fax Number"
                id="faxNumber"
                className=""
                name="faxNumber"
                autoComplete="faxNumber"
                fullWidth
                value={values.faxNumber}
                onChange={handleChange}
                onBlur={handleBlur}
                />


              <div className="input-container">
                <InputLabel htmlFor="hasRx30">Pharmacy Chain</InputLabel>
                <Select
                  value={values.chain}
                  onChange={handleChange}
                  fullWidth
                  inputProps={{
                    name: 'chain',
                    id: 'chain',
                  }}>
                  <MenuItem value=""> --- Select a Chain --- </MenuItem>
                  {(!loading && chains && chains.length > 0) && 
                    chains.map((chain: PharmacyChain) => 
                    <MenuItem key={chain.id} value={chain.id}>{chain.name}</MenuItem>
                    
                  ) }
                  
                </Select>
              </div>
              <div className="input-container">
                <InputLabel htmlFor="hasRx30">has Delivery?</InputLabel>
                <Select
                  value={values.hasDelivery}
                  onChange={handleChange}
                  fullWidth
                  inputProps={{
                    name: 'hasDelivery',
                    id: 'hasDelivery',
                  }}>
                  <MenuItem value="NO">No</MenuItem>
                  <MenuItem value="YES">Yes</MenuItem>
                </Select>
              </div>

              <div className="input-container">
                <InputLabel htmlFor="hasRx30">App Client Group</InputLabel>
                <Select
                  value={values.apiKey}
                  onChange={handleChange}
                  fullWidth
                  inputProps={{
                    name: 'apiKey',
                    id: 'apiKey',
                  }}>
                  <MenuItem value=""> --- Select a API Group --- </MenuItem>
                  {(!keyloading && apiKeys && apiKeys.length > 0) &&
                    apiKeys.map((apiKey: APIKeyDTO) =>
                      <MenuItem key={apiKey.apiKey} value={apiKey.apiKey}>{apiKey.client}</MenuItem>
                    )}

                </Select>
              </div>

              <div className="input-container">
                <InputLabel htmlFor="hasRx30">has Rx30?</InputLabel>
                <Select
                  value={values.hasRx30}
                  onChange={handleChange}
                  fullWidth
                  inputProps={{
                    name: 'hasRx30',
                    id: 'hasRx30',
                  }}>
                  <MenuItem value="NO">No</MenuItem>
                  <MenuItem value="YES">Yes</MenuItem>
                </Select>
              </div>
              {values.hasRx30 === 'YES' && 
                <TextField
                  error={errors.rx30Number && true}
                  id="rx30Number"
                  label={errors.rx30Number ? errors.rx30Number : 'Rx30 Number'}
                  className=""
                  name="rx30Number"
                  autoComplete="rx30Number"
                  margin="normal"
                  fullWidth
                  value={values.hasRx30 === 'YES' && values.rx30Number}
                  placeholder="Pharmacy Rx30 Number"
                  onChange={handleChange}
                  // onBlur={handleBlur}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  />
              }

              <ThemeButton
                isDisabled={isSubmitting}
                text="Save Changes"
                clases="tc-btn"
                color="primary"
                variant="contained"
                />
            </form>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};
