import * as React from 'react';
import RegularLogo from '../../../../assets/svg/OnTimePharmacy-Logo.svg';
import MinLogo from '../../../../assets/svg/logo-min.svg';
import './logo.scss';
import { useDemensions } from '../../../hooks/useDemensions';

interface LogoProps {
  toggle: boolean;
  toggleAction: string;
}

export const Logo: React.FC<LogoProps> = ({
  toggle,
  toggleAction,
}: LogoProps): JSX.Element => {
  const logo = toggle ? MinLogo : RegularLogo;
  const onTablet = useDemensions('(max-width: 768px)');

  return (
    <>
      <span className={`logo-host ${!onTablet ? toggleAction : 'minimized'}`}>
        <div className="logo-wrap">
          <img
            src={!onTablet ? logo : MinLogo}
            className="logo"
            alt="app logo"
          />
        </div>
      </span>
    </>
  );
};
