import { PharmacyChainsStates, GET_CHAINS_SUCCESS, GET_CHAINS_FAIL } from '../types/chain';
import { PharmacyChainsType } from '../types/actions';

const initialState: PharmacyChainsStates = {
  loading: true,
  chains: [],
};

export const chainReducer = (
  state = initialState,
  action: PharmacyChainsType,
): PharmacyChainsStates => {
  const { type, payload } = action;
  switch (type) {
    case GET_CHAINS_SUCCESS:
      return {
        ...state,
        loading: false,
        chains: payload,
      };
    case GET_CHAINS_FAIL:
      return {
        ...state,
        loading: false,
        chains: [],
      };
    default:
      return state;
  }
};
