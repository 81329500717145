import { PharmacyConfigurationType } from '../types/actions';
import { GET_PHARMACIES_CONFIGURATION_FAIL, GET_PHARMACIES_CONFIGURATION_SUCCESS, PharmacyConfigurationStates } from '../types/configuration';

const initialState: PharmacyConfigurationStates = {
  loading: true,
  configuration: null,
};

export const pharmacyConfigurationReducer = (
  state = initialState,
  action: PharmacyConfigurationType,
): PharmacyConfigurationStates => {
  const { type, payload } = action;

  switch (type) {
    case GET_PHARMACIES_CONFIGURATION_SUCCESS:
      return {
        ...state,
        loading: false,
        configuration: payload,
      };
    case GET_PHARMACIES_CONFIGURATION_FAIL:
      return {
        ...state,
        loading: false,
        configuration: null,
      };
    default:
      return state;
  }
};
