import { User } from './User';
import { Pharmacy } from './Pharmacy';
import { Pharmacist } from './Pharmacist';

export interface SearchStates {
  loading: boolean;
  list?: User[] | Pharmacy[] | Pharmacist[];
}

export const SET_USER_SEARCH = 'SET_USER_SEARCH';
export const ERROR_USER_SEARCH = 'ERROR_USER_SEARCH';

export interface SetUserSearch {
  type: typeof SET_USER_SEARCH;
  payload: User[] | Pharmacy[] | Pharmacist[];
}

export interface ErrorUserSearch {
  type: typeof ERROR_USER_SEARCH;
  payload: [];
}
