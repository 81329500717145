import React, { useState } from 'react';
import { Card, CardContent, CardHeader, Divider } from '@material-ui/core';
import { ThemeButton, SelectField } from '../../App.components';
import './account.scss';

const timeZones = [
  {
    value: '0',
    label: 'UTC-5 - 04:00 Central Time',
  },
  {
    value: '1',
    label: 'UTC-4 - 05:00 Eastern Time',
  },
  {
    value: '2',
    label: 'UTC-3 - 06:00 Mountain Time',
  },
  {
    value: '4',
    label: 'UTC+1 - 08:00 London, United Kingdom',
  },
];

const ranges = [
  {
    value: '0-20',
    label: '0 to 20',
  },
  {
    value: '21-50',
    label: '21 to 50',
  },
  {
    value: '51-100',
    label: '51 to 100',
  },
];
interface State {
  timeZones: string;
  option2: string;
}

export const Account: React.FC = (): JSX.Element => {
  const [values, setValues] = useState<State>({
    timeZones: '0',
    option2: '',
  });

  const handleChange = (prop: keyof State): any => (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setValues({ ...values, [prop]: event.target.value });
  };

  return (
    <>
      <div className="account-container">
        <Card className="mb-0 card">
          <CardHeader className="title" title="Account preferences" />
          <Divider className="divider" />
          <CardContent className="card-content">
            <div className="selects">
              <div className="row">
                <div className="col-12 col-md-6">
                  <SelectField
                    label="Time zone"
                    value={values.timeZones}
                    onChange={handleChange('timeZones')}
                    options={timeZones}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <SelectField
                    required={true}
                    label="Time zone"
                    value={values.option2}
                    onChange={handleChange('option2')}
                    options={ranges}
                  />
                </div>
              </div>
            </div>
            <ThemeButton
              text="Save"
              clases="tc-btn"
              color="primary"
              variant="contained"
            />
          </CardContent>
        </Card>
      </div>
    </>
  );
};
