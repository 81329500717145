import * as React from 'react';
import { ClipLoader } from 'react-spinners';
import { makeStyles } from '@material-ui/styles';
import { Theme, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      alignContent: 'center',
      height: '78vh',
    },
    container: {
      padding: theme.spacing(5, 3),
      maxWidth: '100%',
      //boxShadow: '0 6px 18px rgba(0, 0, 0, 0.06)',
    },
    textField: {},
  }),
);

export const Loading: React.FC = (): JSX.Element => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <ClipLoader sizeUnit={'px'} size={150} color={'#5FC9F8'} />
      </div>
    </div>
  );
};
