import * as React from 'react';
import {
  Dialog,
  TextField,
  DialogContent,
} from '@material-ui/core';
import useFormValidation from '../../hooks/useFormValidation';
import { validateChainData} from '../../util/validation';
import {
  ThemeButton,
  DialogTitle,
} from '../../layout/App.components';
import { useDispatch } from 'react-redux';
import { regularAlert } from '../../util/systemAlert';
import { createPharmacyChain } from '../../services/actions/pharmacy';

interface EditModalProps {
  isOpen: boolean;
  handleClose: () => void;
}

interface ModalStates {
  name: string | any;
  description: string | any;
}

export const CreateChainModal: React.FC<EditModalProps> = ({
  isOpen,
  handleClose,
}: EditModalProps): JSX.Element => {
  const dispatch = useDispatch();

  const initialState: ModalStates = {
    name: '',
    description: '',
  };

  const onSubmit = () => {
    regularAlert({
      callback: () => {
        cancelSubmit();
        return dispatch(
          createPharmacyChain({
            name: values.name,
            description: values.description,
          }),
        );
      },
      successConfirmation: {
        title: 'Created!',
        text: 'Chain has been added Successfully.',
      },
      redirect: () => {
        cleanValues();
        handleClose();
      },
      callbackIfError: () => cancelSubmit(),
    });
  };

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    setValues,
    isSubmitting,
    setSubmitting,
    errors,
  } = useFormValidation(initialState, validateChainData, onSubmit);

  const cleanValues = (): void => {
    setValues(initialState);
  };

  const cancelSubmit = (): void => {
    setSubmitting(false);
  };
  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title">
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Add Pharmacy Chain
        </DialogTitle>
        <DialogContent dividers>
          <div className="edit-form-container">
            <form onSubmit={handleSubmit} method="POST">
              <TextField
                error={errors.name && true}
                id="lastName"
                label={errors.name ? errors.name : 'Pharmacy Chain'}
                className=""
                name="name"
                autoComplete="name"
                margin="normal"
                fullWidth
                placeholder="name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                InputLabelProps={{
                  shrink: true,
                }}
                />
              <TextField
                error={errors.description && true}
                id="firstName"
                label={errors.description ? errors.description : 'Description'}
                className=""
                name="description"
                autoComplete="description"
                margin="normal"
                fullWidth
                value={values.description}
                placeholder="Description"
                onChange={handleChange}
                onBlur={handleBlur}
                InputLabelProps={{
                  shrink: true,
                }}
                />
              <ThemeButton
                isDisabled={isSubmitting}
                text="Add Chain"
                clases="tc-btn"
                color="primary"
                variant="contained"
                />
            </form>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};
