import { ConfigurationFormType, GET_PHARMACIES_CONFIGURATION_FAIL, GET_PHARMACIES_CONFIGURATION_SUCCESS } from '../types/configuration';
import {
  DELETE_MULTIPLE_PHARMACIES_ERROR,
  DELETE_MULTIPLE_PHARMACIES_SUCCESS,
  GET_PHARMACIES_FAIL,
  GET_PHARMACIES_SUCCESS,
} from '../types/Pharmacy';
import { GET_CHAINS_FAIL, GET_CHAINS_SUCCESS } from '../types/chain';

import { API_HOST } from '../../../config';
import { AnyAction } from 'redux';
import { Dispatch } from 'react';
import { SET_USER_SEARCH } from '../types/Search';
import axios from 'axios';
import { getReports } from './report';
import { loadUser } from './auth';

export const getPharmacies = (): any => async (
  dispatch: Dispatch<AnyAction>,
  // eslint-disable-next-line consistent-return
): Promise<any> => {
  try {
    const response = await axios.get(`${API_HOST}/ontime_admin/pharmacies`);

    dispatch({
      type: GET_PHARMACIES_SUCCESS,
      payload: response.data,
    });

    dispatch({
      type: SET_USER_SEARCH,
      payload: response.data,
    });

    dispatch(loadUser());

    return {
      response: response.data,
      success: true,
    };
  } catch (error) {
    dispatch({
      type: GET_PHARMACIES_FAIL,
    });
  }
};

export const createPharmacy = (data: any): any => async (
  dispatch: Dispatch<AnyAction>,
): Promise<any> => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify(data);

  try {
    const response = await axios.post(
      `${API_HOST}/ontime_admin/pharmacies/create`,
      body,
      config,
    );

    dispatch(getPharmacies());
    dispatch(getReports());
    dispatch(loadUser());
    return {
      action: true,
      message: '',
      data: response.data,
    };
  } catch (error: any) {
    return {
      action: false,
      message:
        error.response && error.response.data
          ? error.response.data.message
          : '',
    };
  }
};

export const deletePharmacy = (id: string): any => async (
  dispatch: Dispatch<AnyAction>,
): Promise<any> => {
  try {
    await axios.delete(`${API_HOST}/ontime_admin/pharmacies/${id}`);

    dispatch(getReports());
    dispatch(loadUser());
    return {
      action: true,
      message: '',
    };
  } catch (error: any) {
    return {
      action: false,
      message:
        error.response && error.response.data
          ? error.response.data.message
          : '',
    };
  }
};

export const deleteMultiplePharmacies = (ids: string[]): any => async (
  dispatch: Dispatch<AnyAction>,
): Promise<any> => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      ids,
    },
  };

  try {
    const response = await axios.delete(
      `${API_HOST}/ontime_admin/pharmacies/delete/multiple`,
      config,
    );

    dispatch({
      type: DELETE_MULTIPLE_PHARMACIES_SUCCESS,
      payload: response.data,
    });

    dispatch(getPharmacies());
    dispatch(getReports());
    dispatch(loadUser());
    return {
      action: true,
      message: '',
    };
  } catch (error: any) {

    dispatch({
      type: DELETE_MULTIPLE_PHARMACIES_ERROR,
    });
    return {
      action: false,
      message:
        error.response && error.response.data
          ? error.response.data.message
          : '',
    };
  }
};

export const statusPharmacy = (id: any, data: any): any => async (
  dispatch: Dispatch<AnyAction>,
): Promise<any> => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify(data);

  try {
    await axios.put(
      `${API_HOST}/ontime_admin/pharmacies/status/${id}`,
      body,
      config,
    );

    dispatch(getPharmacies());
    dispatch(getReports());
    dispatch(loadUser());
    return {
      action: true,
      message: '',
    };
  } catch (error: any) {
    return {
      action: false,
      message:
        error.response && error.response.data
          ? error.response.data.message
          : '',
    };
  }
};

export const updatePharmacy = (id: string, data: any): any => async (
  dispatch: Dispatch<AnyAction>,
): Promise<any> => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify(data);

  try {
    await axios.put(
      `${API_HOST}/ontime_admin/pharmacies/update/${id}`,
      body,
      config,
    );

    dispatch(getPharmacies());
    dispatch(getReports());
    dispatch(loadUser());
    return {
      action: true,
      message: '',
    };
  } catch (error: any) {
    return {
      action: false,
      message:
        error.response && error.response.data
          ? error.response.data.message
          : '',
    };
  }
};

export const uploadImage = (formData: any): any => async (
  dispatch: Dispatch<AnyAction>,
): Promise<any> => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  try {
    await axios.post(
      `${API_HOST}/ontime_admin/pharmacies/upload`,
      formData,
      config,
    );

    dispatch(getPharmacies());
    dispatch(getReports());
    dispatch(loadUser());
    return {
      action: true,
      message: '',
    };
  } catch (error: any) {
    return {
      action: false,
      message:
        error.response && error.response.data
          ? error.response.data.message
          : '',
    };
  }
};

export const getChains = (): any => async (
  dispatch: Dispatch<AnyAction>,
): Promise<any> => {
  try {
    const response = await axios.get(
      `${API_HOST}/ontime_admin/pharmacies/chains/get`,
    );
    dispatch({
      type: GET_CHAINS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: GET_CHAINS_FAIL,
    });
  }
};

export const createPharmacyChain = (data: any): any => async (
  dispatch: Dispatch<AnyAction>,
): Promise<any> => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify(data);

  try {
    await axios.post(
      `${API_HOST}/ontime_admin/pharmacies/chains/create`,
      body,
      config,
    );

    dispatch(getChains());
    dispatch(loadUser());
    return {
      action: true,
      message: '',
    };
  } catch (error: any) {
    return {
      action: false,
      message:
        error.response && error.response.data
          ? error.response.data.message
          : '',
    };
  }
};

export const updatePharmacyChain = (data: any): any => async (
  dispatch: Dispatch<AnyAction>,
): Promise<any> => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify(data);

  try {
    await axios.put(
      `${API_HOST}/ontime_admin/pharmacies/chains/update`,
      body,
      config,
    );

    dispatch(getChains());
    dispatch(loadUser());
    return {
      action: true,
      message: '',
    };
  } catch (error: any) {
    return {
      action: false,
      message:
        error.response && error.response.data
          ? error.response.data.message
          : '',
    };
  }
};

export const uploadChainImage = (formData: any): any => async (
  dispatch: Dispatch<AnyAction>,
): Promise<any> => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  try {
    await axios.post(
      `${API_HOST}/ontime_admin/pharmacies/chain/update/upload`,
      formData,
      config,
    );

    dispatch(getChains());
    dispatch(loadUser());
    return {
      action: true,
      message: '',
    };
  } catch (error: any) {
    return {
      action: false,
      message:
        error.response && error.response.data
          ? error.response.data.message
          : '',
    };
  }
};

/**
 * =====================================================================================================
 * ===================================== Configuration =================================================
 * =====================================================================================================
 * @param data 
 * @returns 
 */

export const getPharmacyConfiguration = (data: { pharmacyId: string }): any => async (
  dispatch: Dispatch<AnyAction>,
): Promise<void> => {

  try {

    const response = await axios.get(
      `${API_HOST}/ontime_admin/pharmacies/configuration/${data.pharmacyId}`
    );

    dispatch({
      type: GET_PHARMACIES_CONFIGURATION_SUCCESS,
      payload: response.data,
    });

    dispatch(loadUser());
  } catch (error) {

    dispatch({
      type: GET_PHARMACIES_CONFIGURATION_FAIL
    })
  }
}


export const updatePharmacyConfiguration = (data: ConfigurationFormType): any => async (
  dispatch: Dispatch<AnyAction>,
): Promise<any> => {

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify(data);
  try {

    const response = await axios.put(
      `${API_HOST}/ontime_admin/pharmacies/configuration`,
      body,
      config
    );

    dispatch({
      type: GET_PHARMACIES_CONFIGURATION_SUCCESS,
      payload: response.data,
    });

    dispatch(loadUser());
    return {
      action: true,
      message: '',
    };
  } catch (error: any) {
    dispatch({
      type: GET_PHARMACIES_CONFIGURATION_FAIL
    });
    return {
      action: false,
      message:
        error.response && error.response.data
          ? error.response.data.message
          : '',
    };
  }
}


// TODO: Delete this afer generate all system pharmacies configuration

export const generateConfiguration = (data: { pharmacyId: string }): any => async (
  dispatch: Dispatch<AnyAction>,
): Promise<any> => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify(data);

  try {
    await axios.post(
      `${API_HOST}/ontime_admin/pharmacies/configuration/`,
      body,
      config,
    );

    dispatch(getPharmacies());
    dispatch(getReports());
    dispatch(loadUser());
    return {
      action: true,
      message: '',
    };
  } catch (error: any) {
    return {
      action: false,
      message:
        error.response && error.response.data
          ? error.response.data.message
          : '',
    };
  }
}
