import {
  GET_USER_PROFILE_ERROR,
  GET_USER_PROFILE_SUCCESS,
  USER_CHANGE_PASSWORD_ERROR,
  USER_CHANGE_PASSWORD_SUCCESS,
} from '../types/User';

import { API_HOST } from '../../../config';
import { AnyAction } from 'redux';
import { Dispatch } from 'react';
import axios from 'axios';
import { loadUser } from './auth';
import { setErrorAlert } from './alert';

export const logedUserChangePassword = (data: any, id: string): any => async (
  dispatch: Dispatch<AnyAction>,
): Promise<any> => {
  const { currentPassword, newPassword } = data;

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify({
    id,
    password: currentPassword,
    newPassword,
  });

  try {
    const response = await axios.put(
      `${API_HOST}/ontime_admin/users/change-password`,
      body,
      config,
    );

    dispatch({
      type: USER_CHANGE_PASSWORD_SUCCESS,
      payload: response.data,
    });

    dispatch(loadUser());
    return {
      action: true,
      message: '',
    };
  } catch (error: any) {
    console.log(error.response);
    dispatch({
      type: USER_CHANGE_PASSWORD_ERROR,
    });
    return {
      action: false,
      message:
        error.response && error.response.data
          ? error.response.data.message
          : '',
    };
  }
};

export const updateProfole = (data: any, id: string): any => async (
  dispatch: Dispatch<AnyAction>,
): Promise<any> => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify({
    id,
    ...data,
  });

  try {
    const response = await axios.put(
      `${API_HOST}/ontime_admin/users/profile`,
      body,
      config,
    );

    dispatch({
      type: GET_USER_PROFILE_SUCCESS,
      payload: response.data,
    });

    dispatch(loadUser());
    return {
      action: true,
      message: '',
    };
  } catch (error: any) {
    console.log(error.response);
    dispatch({
      type: GET_USER_PROFILE_ERROR,
    });
    return {
      action: false,
      message:
        error.response && error.response.data
          ? error.response.data.message
          : '',
    };
  }
};

export const resetPassword = (data: any): any => async (
  dispatch: Dispatch<AnyAction>,
): Promise<any> => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = JSON.stringify({
    email: data,
  });

  try {
    const response = await axios.post(
      `${API_HOST}/ontime_admin/users/forgot-my-password`,
      body,
      config,
    );

    return {
      action: true,
      message: '',
      response,
    };
  } catch (error: any) {
    console.log({ error: error.response });

    dispatch(
      setErrorAlert(
        error.response && error.response.data
          ? error.response.data.message
          : '',
      ),
    );

    return {
      action: false,
      message:
        error.response && error.response.data
          ? error.response.data.message
          : '',
    };
  }
};
