export const GET_REPORTS_SUCCESS = 'GET_REPORTS_SUCCESS';
export const GET_REPORTS_ERROR = 'GET_REPORTS_ERROR';

export interface AdminReport {
  activePharmacies: {
    pharmaciesCount: number;
    hasRx30: {
      yesCount: number;
      noCount: number;
    };
  };

  mobileAppUsers: {
    userCount: number;
    demographic: {
      femaleCount: number;
      maleCount: number;
    };
  };

  mobileAppOS: {
    IOSCount: number;
    AndroidCount: number;
  };
}

export const inicialAdminReport: AdminReport = {
  activePharmacies: {
    pharmaciesCount: 0,
    hasRx30: {
      yesCount: 0,
      noCount: 0,
    },
  },
  mobileAppUsers: {
    userCount: 0,
    demographic: {
      femaleCount: 0,
      maleCount: 0,
    },
  },
  mobileAppOS: {
    IOSCount: 0,
    AndroidCount: 0,
  },
};

export interface ReportStates {
  loading: boolean;
  report?: AdminReport | null;
}

export interface GetReportSuccessAction {
  type: typeof GET_REPORTS_SUCCESS;
  payload: AdminReport;
}

export interface GetReportErrorAction {
  type: typeof GET_REPORTS_ERROR;
  payload: AdminReport;
}



/**
======================================================================
====================== Pharmacy Report Type ==========================
======================================================================
*/

export const SET_REPORT = 'SET_REPORT';
export const ERROR_SET_REPORT = 'ERROR_SET_REPORT';

export interface ReportCount {
  patientCount: PatientCount;
  prescriptionCount: PrescriptionCount;
  pharmacistsCount: number;
}

export interface PatientCount {
  count: number;
  masculines: number;
  feminines: number;
}

export interface PrescriptionCount {
  count: number;
  completed: number;
  uploadToday: number;
}

export interface PharmacyReportsStates {
    loading: boolean;
    reports?: ReportCount | null;
  }
  
  export interface SetPharmacyReports {
    type: typeof SET_REPORT;
    payload: ReportCount;
  }
  
  export interface ErrorSetPharmacyReports {
    type: typeof ERROR_SET_REPORT;
    payload: null;
  }
  

