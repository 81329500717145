import * as React from 'react';
import {
  Card,
  CardHeader,
  Divider,
  CardContent,
  makeStyles,
  createStyles,
} from '@material-ui/core';
import { Doughnut } from 'react-chartjs-2';

export interface DataSetType {
  data: number[] | null;
  backgroundColor: string[] | null;
  hoverBackgroundColor: string[] | null;
}

export interface ChartDataType {
  labels: string[] | null;
  datasets: DataSetType[] | null;
}

interface PercentData {
  percent: number;
  color: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      boxShadow: '0 6px 18px rgba(0, 0, 0, 0.06)',
    },
  }),
);

const optionsData = (value: PercentData) => {

  const options = {
    cutoutPercentage: 90,
    elements: {
      center: {
        text: `${value.percent}%`,
        color: value.color, // Default is #000000
      },
    },
    legend: {
      display: true,
      position: 'right',
      labels: {
        usePointStyle: true,
        padding: 10,
      },
    },
  };

  return options;
}


const plugins = [
  {
    beforeDraw(chart: any) {
      if (chart.config.options.elements.center) {
        // Get ctx from string
        const { ctx } = chart.chart;

        // Get options from the center object in options
        const centerConfig = chart.config.options.elements.center;
        const fontStyle = centerConfig.fontStyle || 'Arial';
        const txt = centerConfig.text;
        const color = centerConfig.color || '#000';
        const sidePadding = centerConfig.sidePadding || 20;
        const sidePaddingCalculated =
          (sidePadding / 100) * (chart.innerRadius * 2);
        // Start with a base font of 30px
        ctx.font = '40px ' + fontStyle;

        // Get the width of the string and also the width of the element minus 10 to give it 5px side padding
        const stringWidth = ctx.measureText(txt).width;
        const elementWidth = chart.innerRadius * 2 - sidePaddingCalculated;

        // Find out how much the font can grow in width.
        const widthRatio = elementWidth / stringWidth;
        const newFontSize = Math.floor(30 * widthRatio);
        const elementHeight = chart.innerRadius * 2;

        // Pick a new font size so it will not be larger than the height of label.
        const fontSizeToUse = Math.min(newFontSize, elementHeight);

        // Set font settings to draw it correctly.
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        const centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
        const centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
        ctx.font = fontSizeToUse + 'px ' + fontStyle;
        ctx.fillStyle = color;

        // Draw text in center
        ctx.fillText(txt, centerX, centerY);
      }
    },
  },
];


interface CircularChartProps {
  title?: string;
  data: ChartDataType | [];
}

export const CircularChart: React.FC<CircularChartProps> = ({
  title,
  data,
}: any): JSX.Element => {

  const getPercentData = (): PercentData => {
    const getBigNumber: number = Math.max(...data.datasets[0].data)
    const bigNumberIndex: number = data.datasets[0].data.indexOf(getBigNumber);
    const getBigNumberColor: string = data.datasets[0].backgroundColor[bigNumberIndex];

    const sum: number = data.datasets[0].data.reduce((a: number, b: number) => a + b, 0);
    const obtained: number = Math.round(getBigNumber);
    const total: number = sum;
    const percent: number = obtained * 100 / total;
    const porcentCal: number = Number.isNaN(Math.round(percent)) ? 0 : Math.round(percent);

    return {
      percent: porcentCal,
      color: getBigNumberColor
    }
  }

  const options = optionsData(getPercentData());
  const classes: Record<'container', string> = useStyles();



  return (
    <Card className={classes.container}>
      <CardHeader avatar={title} />
      <Divider />
      <CardContent>
        <Doughnut data={data} options={options} plugins={plugins} />
      </CardContent>
    </Card>
  );
};
