import * as React from 'react';
import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined';
import './notifications-menu.scss';
import {
  Badge,
  makeStyles,
  Theme,
  createStyles,
  withStyles,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { MenuProps } from '@material-ui/core/Menu';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      margin: theme.spacing(2),
    },
  }),
);
const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    marginTop: '30.5px',
    width: '300px',
    boxShadow: '0 0 16px rgba(0, 0, 0, 0.14)',
    borderRadius: '4px',
    maxWidth: '300px',
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(() => ({
  root: {
    '&:hover': {
      background: '#fff',
    },
    '&:focus': {
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {},
    },
  },
}))(MenuItem);

export const NotificationsMenu: React.FC = (): JSX.Element => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  return (
    <>
      <Badge
        className={classes.margin}
        color="secondary"
        invisible={false}
        variant="dot">
        <span onClick={handleClick}>
          <NotificationsNoneOutlinedIcon
            className="mat-icon with-badge"
            fontSize="large"
          />
        </span>
      </Badge>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted={true}
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        <StyledMenuItem>
          <div className="item-content">
            <NotificationsNoneOutlinedIcon
              className="mat-icon"
              fontSize="large"
            />
            <div className="text">
              <a className="regular link" href="./#">
                A new pharmacy has been created
              </a>
              <p className="regular-10pt">17 minutes ago</p>
            </div>
          </div>
        </StyledMenuItem>
        
      </StyledMenu>
    </>
  );
};
