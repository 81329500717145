import { Configuration } from './configuration';
import { PharmacyRelations } from './Pharmacy';

export const GET_CHAINS_SUCCESS = 'GET_CHAINS_SUCCESS';
export const GET_CHAINS_FAIL = 'GET_CHAINS_FAIL';

export interface PharmacyChain {
  id: string;
  name: string;
  logo: string;
  theme: string;
  description: string;
  createdAt: Date;
  upatedAt: Date;
  pharmacy?: PharmacyRelations[];
  configuration?: Configuration;
}

export interface PharmacyChainsStates {
  loading: boolean;
  chains: PharmacyChain[] | null | undefined | any;
}

export interface GetPharmacyChainsAction {
  type: typeof GET_CHAINS_SUCCESS;
  payload: PharmacyChain[];
}


export interface ErrorGetPharmacyChainsAction {
  type: typeof GET_CHAINS_FAIL;
  payload: PharmacyChain[];
}
