import * as React from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { InputBase } from '@material-ui/core';
import './search.scss';
import { useDispatch, useSelector } from 'react-redux';
import { User } from '../../../services/types/User';
import { Pharmacy } from '../../../services/types/Pharmacy';
import { searchAction } from '../../../services/actions/search';


export const Search: React.FC = (): JSX.Element => {
  const { list } = useSelector((state: any) => state.search);
  const [values, setValues] = React.useState({
    search: ''
  });
  const dispatch = useDispatch();

  const getPathName = window.location.pathname;

  const pharmacyList = '/dashboard/pharmacies-list';
  const userList = '/dashboard/user-list'

  const isValidPathName = (getPathName === userList || getPathName === pharmacyList);

  const handleSearch = (_event: any): void => {
    setValues({
      ...values,
      [_event.target.name]: _event.target.value,
    });

    const filter = _event.target.value.toLowerCase();

    const searchBy = (options: {
      name: string,
      email: string
    }) => {
      const hasName = options.name.toLowerCase().includes(filter);
      const hasEmail = options.email.toLowerCase().includes(filter);
      let result: boolean = false;

      if (hasName) {
        result = hasName
      } else if (hasEmail) {
        result = hasEmail;
      }

      return result
    }

    let updatedList;
    if (getPathName === userList) {

      updatedList = list.filter((user: User): boolean => searchBy({
        name: `${user.firstName} ${user.lastName}`,
        email: user.email
      }));

    } else if (getPathName === pharmacyList) {

      updatedList = list.filter((pharmacy: Pharmacy): boolean => searchBy({
        name: pharmacy.name,
        email: pharmacy.email
      }))

    }

    dispatch(searchAction(updatedList, getPathName))
  }

  return (
    <div className="search">
      {isValidPathName && (
        <div className="search-input-wrap">
          <SearchIcon className="icon" />

          <div className="mat-form-field p-0">
            <div className="mat-form-field-wrapper">
              <InputBase
                id="search"
                name="search"
                type="text"
                placeholder="Search for..."
                fullWidth={true}
                inputProps={{ 'aria-label': 'Search' }}
                className="p-0 mat-input-element"
                onChange={handleSearch}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  )
};
