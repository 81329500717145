export const cities = [
  'Adjuntas',
  'Aguada',
  'Aguadilla',
  'Aguas Buenas',
  'Aibonito',
  'Añasco',
  'Arecibo',
  'Arroyo',
  'Barceloneta',
  'Barranquitas',
  'Bayamón',
  'Cabo Rojo',
  'Caguas',
  'Camuy',
  'Canovanas',
  'Carolina',
  'Carolina - Isla Verde',
  'Cataño',
  'Cayey',
  'Ceiba',
  'Ciales',
  'Cidra',
  'Coamo',
  'Comerío',
  'Corozal',
  'Culebra',
  'Dorado',
  'Fajardo',
  'Florida',
  'Guánica',
  'Guayama',
  'Guayanilla',
  'Guaynabo',
  'Gurabo',
  'Hatillo',
  'Hormigueros',
  'Humacao',
  'Humacao-Palmas',
  'Isabela',
  'Jayuya',
  'Juana Díaz',
  'Juncos',
  'Lajas',
  'Lares',
  'Las Marías',
  'Las Piedras',
  'Loiza',
  'Luquillo',
  'Manatí',
  'Maricao',
  'Maunabo',
  'Mayagüez',
  'Moca',
  'Morovis',
  'Naguabo',
  'Naranjito',
  'Orocovis',
  'Patillas',
  'Peñuelas',
  'Ponce',
  'Quebradillas',
  'Rincón',
  'Río Grande',
  'Sabana Grande',
  'Salinas',
  'San Germán',
  'San Juan',
  'San Juan-Condado-Miramar',
  'San Juan-Hato Rey',
  'San Juan-Río Piedras',
  'San Juan-Santurce',
  'San Juan-Viejo SJ',
  'San Lorenzo',
  'San Sebastián',
  'Santa Isabel',
  'Toa Alta',
  'Toa Baja',
  'Toa Baja-Levittown',
  'Trujillo Alto',
  'Utuado',
  'Vega Alta',
  'Vega Baja',
  'Vieques',
  'Villalba',
  'Yabucoa',
  'Yauco',
];

export const state = [
  'Alabama',
  'Alaska',
  'American Samoa',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'District of Columbia',
  'Federated States of Micronesia',
  'Florida',
  'Georgia',
  'Guam',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Marshall Islands',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Northern Mariana Islands',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Palau',
  'Pennsylvania',
  'Puerto Rico',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virgin Island',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
];

type Option = {
  value: any,
  view: string
}
export const citySelect = (): Option[] => {
  const citiesList = cities.map((s: string) => {
const newList = {
  value: s,
  view: s
}
  return newList
})
  return citiesList
}


export const stateSelect = [
  {value: 'Puerto Rico', view: 'Puerto Rico'}
]
