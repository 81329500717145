/* eslint-disable jsx-a11y/label-has-associated-control */
import * as React from 'react';
import './pharmacies-list.scss';
import {
  Loading,
  ThemeButton,
  DataTable,
} from '../../layout/App.components';
import { getPharmacies,deleteMultiplePharmacies } from '../../services/actions/pharmacy';
import { useDispatch, useSelector } from 'react-redux';
import { Select, MenuItem } from '@material-ui/core';
import { Pharmacy } from '../../services/types/Pharmacy';
import { CreatePharmacyModal } from './CreatePharmacyModal';
import { useRole } from '../../hooks/useRole';
import { TableOption } from '../../layout/ui/data-table/DataTable';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { Link } from 'react-router-dom';
import {createPharmacyData,PharmacyData} from '../../interfaces/pharmacyData'

export const PharmaciesListPage: React.FC = (): JSX.Element => {
  const { pharmacies, loading } = useSelector((state: any) => state.pharmacies);
  const [userStatus, setUserStatus] = React.useState<string | unknown>('');
  const dispatch = useDispatch();
  const {isReader,isAdmin,isSystemAdmin} = useRole();

  // modal
  const [open, setOpen] = React.useState(false);
  const handleClose = (): void => {
    setOpen(false);
  };

  React.useEffect(() => {
    dispatch(getPharmacies());
  }, [dispatch]);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setUserStatus(event.target.value);
  };

  const filterPharmacyData = (pharmacy: Pharmacy) => {
    switch (userStatus) {
      case 'Rx30':
        return pharmacy.hasRx30;
      case 'NoRx30':
        return !pharmacy.hasRx30;
      case 'Active':
        return pharmacy.isActive;
      case 'Archive':
        return !pharmacy.isActive;
      default:
        return pharmacy;
    }
  };

  let rows: PharmacyData[] | [] = [];

  if (!loading && pharmacies) {
    rows = pharmacies
      .filter((pharmacy: Pharmacy) => filterPharmacyData(pharmacy))
      .map((pharmacy: any) =>
        createPharmacyData(
          pharmacy.id,
          pharmacy.logo,
          pharmacy.isActive,
          pharmacy.name,
          pharmacy.town,
          pharmacy.state,
          pharmacy.phoneNumber,
          pharmacy.hasRx30,
        ),
      );
  }

  const tableHeader: string[] = [
    'Status',
    'Pharmacy Name',
    'City',
    'State',
    'Phone',
    'Rx30',
    'Edit',
  ];

  const tableOption: TableOption<string[], any[]> = {
    tableHeader,
    tableData: rows,
  };

  return (
    <>
      {loading && !pharmacies ? 
        <Loading />
       : 
        <>
          <section className="list-page-header">
            <div className="filter">
              <label className="label" title="user role">
                Role:
              </label>
              <Select
                value={userStatus}
                onChange={handleChange}
                displayEmpty
                name="status">
                <MenuItem value="">
                  <em>All</em>
                </MenuItem>
                <MenuItem value="Rx30">Rx30</MenuItem>
                <MenuItem value="NoRx30">No Rx30</MenuItem>
                <MenuItem value="Active">Active</MenuItem>
                <MenuItem value="Archive">Archive</MenuItem>
              </Select>
            </div>
            <div className="create-btn" onClick={() => setOpen(true)}>
              {!isReader && 
                  <ThemeButton
                    text="Add New Pharmacy"
                    clases="tc-btn"
                    color="primary"
                    variant="contained"
                    />
              }
            </div>
          </section>

          <CreatePharmacyModal
            isOpen={open}
            handleClose={handleClose}
            />

          <DataTable
            tableData={tableOption}
            handleMultipleDelete={selected => deleteMultiplePharmacies(selected)}
            generateCell={(row,column) => {
              const details:  string = '/dashboard/pharmacy-details/'.concat(row.id);
              const value = (t = 'active',f = 'no-active',prop:string):string => (
                row[prop] ? t : f
              );
              switch(column){
                case 'status': {
                  return (
                    <span
                      className={
                      'ont-badge '.concat(
                        value('active','archived','status')
                      )}>
                    {' '}
                    {value('Active','Archived','status')}
                  </span>
                  )
                }
                case 'state': return row.state;
                case 'phone': return row.phoneNumber;
                case 'city': return row.town;
                case 'pharmacy name': {
                  return (
                    <div className="user-wrap">
                      <Link to={details} className="edit-wrap">
                        <span className="name dot-wrap" title={row.username}>
                          {' '}
                          {row.name}{' '}
                        </span>
                      </Link>
                    </div>
                  );
                }
                case 'rx30':{
                  return (
                    <span
                      className={
                        'ont-text-color '.concat(
                          value(undefined,undefined,'hasRx30')
                        )
                      }>
                      {value('Yes','No','hasRx30')}
                    </span>
                  );
                }
                case 'edit':{
                  return (
                    <Link to={details} className="edit-wrap">
                    <div className="edit-text">
                      {isSystemAdmin || isAdmin ? 'Edit' : 'View'}
                    </div>
                    <div className="icon">
                      <EditOutlinedIcon />
                    </div>
                  </Link>
                  )
                }
                default:
                  return null
              }
            }}
            />
        </>
      }
    </>
  );
};
