import * as React from 'react';
import { User } from '../../services/types/User';

interface IProps {
  user: User;
}

export const UserProfileInformation: React.FC<IProps> = ({
  user,
}: IProps): JSX.Element => {
  return (
    <>
      <div className="user-info">
        <div className="contact-header">
          <span>Contact Information</span>
        </div>
        <div className="info-container">
          <div className="info">
            <span className="label">Frist Name</span>
            <span className="info-value"> {user.firstName}</span>
          </div>
          <div className="info">
            <span className="label">Last Name</span>
            <span className="info-value">{user.lastName}</span>
          </div>
          <div className="info">
            <span className="label">Email</span>
            <span className="info-value">{user.email}</span>
          </div>
          <div className="info">
            <span className="label">Phone Number</span>
            <span className="info-value">{user.phoneNumber}</span>
          </div>
        </div>
      </div>
    </>
  );
};
