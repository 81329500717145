
export const GET_PHARMACIES_CONFIGURATION_SUCCESS = 'GET_PHARMACIES_CONFIGURATION_SUCCESS';
export const GET_PHARMACIES_CONFIGURATION_FAIL = 'GET_PHARMACIES_CONFIGURATION_FAIL';

export interface DeliveryMethod {
  refill_rx_integer: number;
  ontime_api_integer: number;
  method: string;
  isActive: boolean;
}

export type DeliveryMethodList = { delibery_methods: DeliveryMethod[] }

export interface GlobaConfigurationType {
  id: string;
  webSite?: string;
  theme?: string;
  reward?: boolean;
  hasDelivery?: boolean;
  hasAutoRefill?: boolean;
  facebook?: string;
  instagram?: string;
  twitter?: string;
  userInfoIsRequired?: boolean;
  shopper?: string;
  globalEmail?: string;
}

export interface Configuration extends GlobaConfigurationType {
  deliveryMethos?: DeliveryMethodList;
}

export interface ConfigurationFormType extends GlobaConfigurationType {
  deliveryMethos: DeliveryMethod[] | undefined;
}


export interface PharmacyConfigurationStates {
  loading: boolean;
  configuration: Configuration | null;
}

export interface GetPharmacyConfigurationAction {
  type: typeof GET_PHARMACIES_CONFIGURATION_SUCCESS;
  payload: Configuration;
}

export interface ErrorGetPharmacyConfigurationAction {
  type: typeof GET_PHARMACIES_CONFIGURATION_FAIL;
  payload: Configuration;
}
