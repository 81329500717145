import './App.scss';

import * as React from 'react';

import {
  EmailConfirmationPage,
  ErrorPage,
  LoginPage,
  PasswordResetPage,
} from './pages/App.pages';
import { Redirect, Route, Switch } from 'react-router';

import { AppContainer } from './AppContainer';
import { LastLocationProvider } from 'react-router-last-location';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { configureStore } from './services/store';
import { loadUser } from './services/actions/auth';
import { setAuthToken } from './util/setauthToken';
import { useEffect } from 'react';

const { store, persistor } = configureStore();
if (localStorage.token) {
  setAuthToken(localStorage.token);
}
export const App: React.FC = (): JSX.Element => {
  const error4O4 = {
    errorCode: '404',
    message: 'Page Not Found!',
  };

  useEffect(() => {
    store.dispatch(loadUser());
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <LastLocationProvider>
            <Switch>
              <Route
                exact
                path="/"
                component={() => <Redirect to="/dashboard" />}
              />
              <Route path="/dashboard" component={AppContainer} />
              <Route path="/login" component={LoginPage} />
              <Route
                path="/email-confirmation"
                component={EmailConfirmationPage}
              />
              <Route path="/forgot-password" component={PasswordResetPage} />
              <Route component={() => <ErrorPage description={error4O4} />} />
            </Switch>
          </LastLocationProvider>
        </Router>
      </PersistGate>
    </Provider>
  );
};
