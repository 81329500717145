import {
  ERROR_SET_REPORT,
  GET_REPORTS_ERROR,
  GET_REPORTS_SUCCESS,
  PharmacyReportsStates,
  ReportStates,
  SET_REPORT,
} from '../types/report';
import { PharmacyReportActionType, ReportActionType } from '../types/actions';

const initialState: ReportStates = {
  loading: true,
  report: null,
};

export const reportReducer = (
  state = initialState,
  action: ReportActionType,
): ReportStates => {
  const { type, payload } = action;

  switch (type) {
    case GET_REPORTS_SUCCESS:
      return {
        ...state,
        loading: false,
        report: payload,
      };
    case GET_REPORTS_ERROR:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};


const initialReportsState: PharmacyReportsStates = {   
  loading: true,
  reports: null
};

export const pharmacyReportsReducer = (
  state = initialReportsState,
  action: PharmacyReportActionType,
): PharmacyReportsStates => {
  const { type, payload } = action;

  switch (type) {
    case SET_REPORT:
      return {
        ...state,
        loading: false,
        reports: payload,
      };
    case ERROR_SET_REPORT:
      return {
        ...state,
        loading: false,
        reports: null,
      };
    default:
      return state;
  }
};
