export const GET_PATIENTS_SUCCESS = 'GET_PATIENTS_SUCCESS';
export const GET_PATIENTS_FAIL = 'GET_PATIENTS_FAIL';

export interface PatientsState {
  count: number;
  genders: any[];
}

export interface GetPatientAction {
  type: string;
  payload: any;
}
