import * as React from 'react';
import './error-alert.scss';
import { useDispatch, useSelector } from 'react-redux';
import { removeErrorAlert } from '../../../services/actions/alert';
import { reduxState } from '../../../services/types/reduxStates';

export const ErrorAlert: React.FC = (): JSX.Element => {
  const [closeAlert, setCloseAlert] = React.useState(false);
  const { message } = useSelector((state: reduxState) => state.alert);

  const dispatch = useDispatch();
  const onClose = (): void => {
    dispatch(removeErrorAlert());
    setCloseAlert(true);
  };

  React.useEffect(() => {
    if (typeof message !== 'undefined') {
      setCloseAlert(false);
    }
  }, [message]);

  return (
    <>
      {!closeAlert && typeof message !== 'undefined' ? (
        <div className="alert alert-danger" role="alert">
          <button type="button" className="close-alert" onClick={onClose}>
            ×
          </button>
          <p>{message}</p>
        </div>
      ) : (
        ' '
      )}
    </>
  );
};
