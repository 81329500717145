import { TextField, Select, FormControl, InputLabel, Checkbox, FormControlLabel, FormHelperText } from '@material-ui/core';
import React from 'react';
import { Controller } from 'react-hook-form';
import { PhoneNumberField } from '../phone-number-input/PhoneNumberInput';


type Props = {
  control: any;
  label: string;
  name: string;
  fullWidth?: boolean;
  disabled?: boolean;
  errors: {
    error: boolean;
    message: string | undefined;
  };
}

// eslint-disable-next-line arrow-body-style
export const FormInput: React.FC<Props> = ({ errors, control, name, label, fullWidth, disabled }): JSX.Element => {

  return (
    <Controller
      render={({ field }) =>
        <TextField
          error={!!errors.error}
          label={label}
          {...field}
          helperText={errors.message ?? ''}
          fullWidth={fullWidth}
          disabled={disabled}
          />}
      control={control}
      name={name}
      defaultValue={10}
      />
  );

}
interface SelectProps extends Props {
  children: React.ReactNode,
}

export const FormSelect: React.FC<SelectProps> = ({ errors, control, name, label, fullWidth, children, disabled }): JSX.Element =>
  <Controller
    render={({ field }) =>
      <FormControl fullWidth={fullWidth}>
        <InputLabel id={name}>{label}</InputLabel>
        <Select
          error={!!errors.error}
          {...field}
          autoWidth={fullWidth}
          disabled={disabled}>
          {children}
        </Select>
      </FormControl>
    }
    control={control}
    name={name}
    defaultValue={10}
    />


export const FormPhoneNumber: React.FC<Props> = ({ errors, control, name, label, fullWidth }): JSX.Element =>
  <Controller
    render={({ field }) =>
      <PhoneNumberField
        label={label}
        props={{
          error: !!errors.error,
          fullWidth,
          ...field
        }
        }
        />
    }
    control={control}
    name={name}
    defaultValue={10}
    />


export const FormCheckbox: React.FC<Props> = ({ control, name, label, errors, disabled }): JSX.Element =>
  <Controller
    render={({ field }) =>
      <div>
        <FormControlLabel
          disabled={disabled}
          control={
            <Checkbox
              {...field}
              color="primary"
              checked={field.value}
              />
          }
          label={label}
          />
        {errors.error &&
          <FormHelperText>{errors.error && errors.message}</FormHelperText>
        }
      </div>
    }
    control={control}
    name={name}
    defaultValue={10}
    />
