import React from 'react'
import { PharmacyChain } from '../../services/types/chain'
import { useDispatch } from 'react-redux';
import { regularAlert } from '../../util/systemAlert';
import { updatePharmacyChain, uploadChainImage } from '../../services/actions/pharmacy';
import useFormValidation from '../../hooks/useFormValidation';
import { validateChainData } from '../../util/validation';
import { Dialog, DialogContent, TextField } from '@material-ui/core';
import { ThemeButton, DialogTitle } from '../../layout/App.components';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';

interface Props {
  chain: PharmacyChain | null;
  isOpen: boolean;
  handleClose: () => void;
}
interface ModalStates {
  name: string | any;
  description: string | any;
}

export const EditChainModal: React.FC<Props> = ({
  chain,
  isOpen,
  handleClose
}: Props): JSX.Element => {
  console.log(chain);
  const [chainEditeData, setChainEditeData] = React.useState<PharmacyChain | null>(chain);
  const [image, setImage] = React.useState({ preview: '', raw: '' });

  const acceptedTypes: string[] = [
    'image/png',
    'image/jpg',
    'image/jpeg',
  ];

  const isValidFileType = (fileType: string): boolean => acceptedTypes.includes(fileType);
  const getFileExtension = (filename: string) => filename.split('.').pop();


  React.useEffect(() => {
    setChainEditeData(chain);
  }, [chain, chainEditeData]);
  const dispatch = useDispatch();

  const initialState: ModalStates = {
    name: chainEditeData && chainEditeData.name,
    description: chainEditeData && chainEditeData.description,
  };

  const handleUploadClick = (e: any) => {
  
    e.preventDefault()

    if (typeof e.target.files[0] !== 'undefined') {


      if (!isValidFileType(e.target.files[0].type)) {
        alert('Only images are allowed, only png or jpg are allowed.');
        return;
      }

      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0]
      });
      const fileName = `${chainEditeData && chainEditeData.id}.${getFileExtension(e.target.files[0].name)}`;
      const file = new File([e.target.files[0]], fileName);
      

      const formData = new FormData()
      formData.append('file', file, fileName);
      formData.append('id', chainEditeData ? chainEditeData.id : '');

      regularAlert({
        callback: () => dispatch(uploadChainImage(formData)),
        successConfirmation: {
          title: 'Updated!',
          text: 'Pharmacy Chain image has been Updated Successfully.',
        }
      });
    }

  };

  const onSubmit = () => {
    regularAlert({
      callback: () => {
        cancelSubmit();
        return dispatch(
          updatePharmacyChain({
            id: chainEditeData && chainEditeData.id,
            name: values.name,
            description: values.description,
          }),
        );
      },
      successConfirmation: {
        title: 'Created!',
        text: 'Chain has been added Successfully.',
      },
      redirect: () => {
        cleanValues();
        handleClose();
      },
      callbackIfError: () => cancelSubmit(),
    });
  };

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    setValues,
    isSubmitting,
    setSubmitting,
    errors,
  } = useFormValidation(initialState, validateChainData, onSubmit);

  const cleanValues = (): void => {
    setValues(initialState);
  };

  const cancelSubmit = (): void => {
    setSubmitting(false);
  };
  const imageSRC = `https://api.ontimepharmacyapp.com/api/statics/image/${chainEditeData ?chainEditeData.logo: ''}`
  
  return (
    <Dialog
      fullWidth={true}
      maxWidth={'sm'}
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title">
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        {chainEditeData ? chainEditeData.name : ''}
        </DialogTitle>
      <DialogContent dividers>
        <div className="edit-form-container">
          <div className="image-chain-container">
            <div className="img-wrap">
              <input
                accept="image/*"
                className="input"
                id="contained-button-file"
                multiple
                type="file"
                onChange={handleUploadClick}
              />

              <label htmlFor="contained-button-file" className="edit-btn"> <EditOutlinedIcon /></label>
              <img
                src={image.preview ? image.preview : imageSRC}
                alt={chainEditeData ? chainEditeData.name : ''}
              />
            </div>
          </div>
          <form onSubmit={handleSubmit} method="POST">

            <TextField
              error={errors.name && true}
              id="lastName"
              label={errors.name ? errors.name : 'Pharmacy Chain'}
              className=""
              name="name"
              autoComplete="name"
              margin="normal"
              fullWidth
              placeholder="name"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              error={errors.description && true}
              id="firstName"
              label={errors.description ? errors.description : 'Description'}
              className=""
              name="description"
              autoComplete="description"
              margin="normal"
              fullWidth
              value={values.description}
              placeholder="Description"
              onChange={handleChange}
              onBlur={handleBlur}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <ThemeButton
              isDisabled={isSubmitting}
              text="Save Chain"
              clases="tc-btn"
              color="primary"
              variant="contained"
            />
          </form>
        </div>
      </DialogContent>
    </Dialog>
  )
}
