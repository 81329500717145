import { Pharmacist } from './Pharmacist';
import { PharmacyChain } from './chain';
import { Configuration } from './configuration';

export const GET_PHARMACIES_SUCCESS = 'GET_PHARMACIES_SUCCESS';
export const GET_PHARMACIES_FAIL = 'GET_PHARMACIES_FAIL';

export const DELETE_MULTIPLE_PHARMACIES_SUCCESS =
  'DELETE_MULTIPLE_PHARMACIES_SUCCESS';
export const DELETE_MULTIPLE_PHARMACIES_ERROR =
  'DELETE_MULTIPLE_PHARMACIES_ERROR';

  export type PharmacyFormType = {
    name: string;
    address: string;
    town: string;
    state: string;
    zipCode: string;
    logo: string;
    longitude: string;
    latitude: string;
    phoneNumber: string;
    faxNumber?: string;
    email: string;
    hasRx30: boolean;
    hasRx30Input: string;
    rx30Number: string;
    apiKey?: string;
    chain?: string;
  }
export interface Pharmacy {
  id: string;
  name: string;
  address: string;
  town: string;
  state: string;
  zipCode: string;
  logo: string;
  longitude?: string;
  latitude?: string;
  phoneNumber: string;
  faxNumber?: string;
  email: string;
  hasRx30: boolean;
  hasDelivery: boolean;
  rx30Number: string;
  isActive: boolean;
  apiKey?: string;
  useGlobalConfiguration?: boolean;
  createdAt?: Date;
  pharmacists?: Pharmacist[];
  reportCount?: IReportCount;
  chain?: PharmacyChain;
  configuration?: Configuration;
}

export interface PharmacyRelations {
  id: string;
  directoryId?: string;
  logo?: string;
  name: string;
  phoneNumber: string;
  email: string;
  address?: string;
  town?: string;
  state?: string;
  zipCode?: string;
  latitude?: string;
  longitude?: string;
  hasDelivery?: boolean;
  apiKey?: string;
  webSite?: string;
  isActive?: boolean;
  confirmation?: boolean;
  configuration?: Configuration;
}

// eslint-disable-next-line @typescript-eslint/interface-name-prefix
export interface IReportCount {
  patientCount: PatientCount;
  prescriptionCount: PrescriptionCount;
  pharmacistsCount: number;
}

export interface PatientCount {
  count: number;
  masculines: number;
  feminines: number;
}

export interface PrescriptionCount {
  count: number;
  completed: number;
  uploadToday: number;
}

export interface PharmacyStates {
  loading: boolean;
  pharmacies?: Pharmacy[] | null | undefined | any;
}

export interface GetPharmacyAction {
  type: typeof GET_PHARMACIES_SUCCESS;
  payload: Pharmacy[];
}

export interface ErrorGetPharmacyAction {
  type: typeof GET_PHARMACIES_FAIL;
  payload: Pharmacy[];
}

export interface DeleteMultiplePharmaciesAction {
  type: typeof DELETE_MULTIPLE_PHARMACIES_SUCCESS;
  payload: Pharmacy[];
}

export interface DeleteMultiplePharmaciesError {
  type: typeof DELETE_MULTIPLE_PHARMACIES_ERROR;
  payload: Pharmacy[];
}
