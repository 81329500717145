import * as React from 'react';
import './top-nav.scss';
import { Search, AccountInfo } from '../../App.components';
import { Link } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import { reduxState } from '../../../services/types/reduxStates';
import { logout } from '../../../services/actions/auth';

export const TopNav: React.FC = (): JSX.Element => {
  const { user, loading } = useSelector((state: reduxState) => state.user);
  const dispatch = useDispatch();
  const logoutAction = (): void => {
    dispatch(logout());
  };

  const pageTitle = 'Page title';

  return (
    <>
      <div className="top-nav-bar">
        <div className="navbar-wrap">
          <Search />
          {/* <NotificationsMenu />{' '} */}
          {(!loading || user) && (
            <Link to="/dashboard/profile">
              <AccountInfo info={user} />
            </Link>
          )}
          <div className="page-header">{pageTitle}</div>
          <Link to="/login" onClick={logoutAction}>
            logout
          </Link>
         
        </div>
      </div>
    </>
  );
};
