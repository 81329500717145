import React, { useState } from 'react';
import { Menu, Logo, SideBarToggle } from '../../App.components';
import './side-bar.scss';

export const SideBar: React.FC = (): JSX.Element => {
  const [toggle, setToggle] = useState<boolean>(false);
  const toggleAction = toggle ? 'minimized' : '';

  const changeNavState = (): void => {
    setToggle(!toggle);
  };
  return (
    <div className={`side-bar ${toggleAction}`}>
      <Logo toggle={toggle} toggleAction={toggleAction} />
      <div className="side-nav-wrap">
        <Menu toggleAction={toggleAction} />
        <div className="d-none d-lg-flex">
          <SideBarToggle changeNavState={changeNavState} toggle={toggle} />
        </div>
      </div>
    </div>
  );
};
