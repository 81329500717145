/* eslint-disable no-return-await */
/* eslint-disable consistent-return */
/* eslint-disable no-underscore-dangle */
// @ts-nocheck
import './data-table.scss';

import * as React from 'react';

import {
  BlueCheckbox,
  EnhancedTableHead,
  TablePaginationActions,
} from '../../App.components';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { confirmationAlert } from '../../../util/systemAlert';
import { myStyles } from './tableStyles';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(() => createStyles({
  ...myStyles,
  root: {
    boxShadow: '0px 6px 18px rgba(0, 0, 0, 0.06)',
    borderRadius: '4px',
    color: '#707683',
    width: '100%',
    overflowX: 'auto',
  },
}));

export interface TableOption<H = string[], D = object[] | [] | any> {
  tableHeader: H;
  tableData: D;
}

interface DataTableProps {
  tableData: TableOption;
  generateCell?: (row:any,column:string,row_index?:number) => React.ReactNode;
  emptyCellValue?: string | React.ReactNode;
  emptyTableMessage?: string | React.ReactNode;
  handleMultipleDelete?: (args?:any) => void | Promise<any>;
  isActiveDelete?:boolean;
  emptyCellsColSpan?: number
}

export const DataTable: React.FC<DataTableProps> = (
  {
    generateCell,
    emptyTableMessage = 'Sorry, no record found',
    emptyCellValue = 'None',
    handleMultipleDelete,
    isActiveDelete = true,
    emptyCellsColSpan = 7,
    ...rest
  }: DataTableProps,
): JSX.Element => {
  const { tableHeader, tableData } = rest.tableData;
  const dispatch = useDispatch();
  const classes = useStyles();
  const [selected, setSelected] = React.useState<string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, tableData.length - page * rowsPerPage);
  const numberFooter = tableHeader.length + 1;

  const isSelected = (name: string): boolean => selected.indexOf(name) !== -1;

  const handleSelectAllClick = (
    _event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    if (_event.target.checked) {
      const newSelecteds: string[] = tableData.map((n: any) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (
    _event: React.MouseEvent<unknown>,
    name: string,
  ): void => {
    if (!isActiveDelete) {
      return;
    }
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ): void => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    _event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void => {
    setRowsPerPage(parseInt(_event.target.value, 10));
    setPage(0);
  };

  const _handleMultipleDelete = (): void => {
    const count: string = selected.length > 1 ? 'Entries' : 'Entry';

    confirmationAlert({
      title: 'Are you sure?',
      text: 'You wont be able to revert this!',
      confirmButtonText: 'Yes',
      successConfirmation: {
        title: 'Deleted',
        text: `${count} deleted.`,
      },
      callback: async () => {
        if(!handleMultipleDelete){
          return;
        }
        return await dispatch(handleMultipleDelete(selected))
      },
      redirect: () => setSelected([]),
    });
  };

  const defaultValues = (row:any,column:string) => {
    /*
      Maybe this function can be extended
      using a config object passed in as a prop to <DataTable/>
      @dev-hernandez2
    */
    const value = row[column];

    if(!value || value.constructor.name === 'Object'){
      /*
        We verify if the value is a
        object because we don't want to crash
        @dev-hernandez2
      */
      if(emptyCellValue){
        return emptyCellValue;
      }
      return null;
    }

    return value
  }

  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <EnhancedTableHead
          classes={classes}
          numSelected={selected.length}
          onSelectAllClick={handleSelectAllClick}
          rowCount={tableData.length}
          tabaleHeader={tableHeader}
          onHandleMultipleDelete={_handleMultipleDelete}
          isActiveDelete={isActiveDelete}
          />
        <TableBody className={classes.tbody}>
          {tableData
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map(
              (row: any, index: number): JSX.Element => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    key={row.id}
                    className={classes.bodyTr}
                    onClick={(
                      event: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
                    ): void => handleClick(event, row.id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    selected={isItemSelected}>
                    {isActiveDelete && 
                      <TableCell
                        className={classes.matCheck}
                        component="th"
                        scope="row">
                        <BlueCheckbox
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                          color="primary"
                          />
                      </TableCell>
                    }
                    {tableHeader.map((header:string) => {
                      const column:string = header.toLowerCase();
                      return (
                        <TableCell className={classes.matCell} align="right" key={header}>
                          { !generateCell
                              ? defaultValues(row,column)
                              : generateCell(row,column,index)
                          }
                        </TableCell>
                      )
                    })}
                  </TableRow>
                );
              },
            )}
          {!tableData.length && 
            <TableRow style={{ height: 48 }}>
              <TableCell
                colSpan={emptyCellsColSpan}
                style={{ textAlign: 'center', fontSize: '24px' }}>
              {emptyTableMessage}
              </TableCell>
            </TableRow>
          }
          {emptyRows > 0 && 
            <TableRow style={{ height: 48 * emptyRows }}>
              <TableCell colSpan={emptyCellsColSpan} />
            </TableRow>
          }
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              colSpan={numberFooter}
              count={tableData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
              />
          </TableRow>
        </TableFooter>
      </Table>
    </Paper>
  );
};
