export interface Data {
  id: string;
  logo: string;
  name: string;
  description: string;
}

export const createChainData = (
  id: string,
  logo: string,
  name: string,
  description: string,
): Data => ({
  id,
  logo,
  name,
  description,
});
