export interface Data {
  id: string;
  avatar: string;
  status: boolean;
  role: string;
  username: string;
  phoneNumber: string;
  email: string;
}

export const createUserData = (
  id: string,
  avatar: string,
  status: boolean,
  role: string,
  username: string,
  phoneNumber: string,
  email: string,
): Data => ({
  id,
  avatar,
  status,
  role,
  username,
  phoneNumber,
  email,
});
