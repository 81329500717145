/* eslint-disable no-shadow */
import { useCallback, useEffect, useState } from 'react';

import { reduxState } from '../services/types/reduxStates';
import { useSelector } from 'react-redux';

export const useSystemRoles = (role: string): boolean => {
  const [state, setState] = useState<boolean>(false);
  const { user, loading } = useSelector((state: reduxState) => state.user);

  const isValidRole = useCallback(() => {
    if (!loading && user) {
      if (user.role === role) setState(true);
    }
  }, [role, loading, user]);

  useEffect(() => {
    isValidRole();
  });

  return state;
};
