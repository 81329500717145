/**
 * Develop by: Think Telic
 * Website: thinktelic.com
 * Company Email: hello@thinktelic.com
 *
 * Software Engenieer: William Hernandez
 * Email: william@thinktelic.com
 *
 * Project Designer: Zoraida Salcedo
 * Email: zoraida@thinktelic.com
 *
 * Project Owner Company: Ontime Soft
 * Website: rx30pr.com
 * Project Owner: Enrique Garay
 * Email: egaray@ontimerx30.com
 */
import * as React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import './page-settings.scss';
import { Account } from '../../layout/App.components';
import PersonIcon from '@material-ui/icons/Person';
import { Breadcrumbs } from '@material-ui/core';
import { Link } from 'react-router-dom';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}>
      <Box p={3}>{children}</Box>
    </Typography>
  );
};

const a11yProps = (index: any): any => ({
  id: `vertical-tab-${index}`,
  'aria-controls': `vertical-tabpanel-${index}`,
});

export const PageSettings: React.FC = (): JSX.Element => {
  const [value, setValue] = React.useState(0);

  const handleChange = (
    _event: React.ChangeEvent<{}>,
    newValue: number,
  ): void => {
    setValue(newValue);
  };

  const accountTap = (
    title: string,
    description: string,
    icon: any,
  ): JSX.Element => (
    <>
      <div className="icon">{icon}</div>
      <div className="text">
        <h3 className="title">{title}</h3>
        <span className="desc">{description}</span>
      </div>
    </>
  );
  return (
    <>
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/">Material-UI</Link>
        <Link to="/getting-started/installation/">Core</Link>
        <Typography color="textPrimary">Breadcrumb</Typography>
      </Breadcrumbs>
      <div className="Page-settings">
        <div className="desktop-tabs">
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs ">
            <Tab
              label={accountTap(
                'Account',
                'Your account preferences',
                <PersonIcon style={{ fontSize: 25 }} />,
              )}
              {...a11yProps(0)}
            />
            <Tab label="Item Two" {...a11yProps(1)} />
            <Tab label="Item Three" {...a11yProps(2)} />
            <Tab label="Item Four" {...a11yProps(3)} />
            <Tab label="Item Five" {...a11yProps(4)} />
            <Tab label="Item Six" {...a11yProps(5)} />
            <Tab label="Item Seven" {...a11yProps(6)} />
          </Tabs>
        </div>

        <div className="displayed-section">
          <TabPanel value={value} index={0}>
            <Account />
          </TabPanel>
          <TabPanel value={value} index={1}>
            Test 2
          </TabPanel>
          <TabPanel value={value} index={2}>
            Item Three
          </TabPanel>
          <TabPanel value={value} index={3}>
            Item Four
          </TabPanel>
          <TabPanel value={value} index={4}>
            Item Five
          </TabPanel>
          <TabPanel value={value} index={5}>
            Item Six
          </TabPanel>
          <TabPanel value={value} index={6}>
            Item Seven
          </TabPanel>
        </div>
      </div>
    </>
  );
};
