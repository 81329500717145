import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import {
    Breadcrumbs,
    Typography,
    Card,
    Grid,
    CardContent,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import './pharmacist-details.scss';
import { useDispatch, useSelector } from 'react-redux';
import { Loading, ThemeButton } from '../../layout/App.components';
import { getPharmacist, removePharmacistPharmacy } from '../../services/actions/pharmacist';
import { setErrorAlert } from '../../services/actions/alert';
import { Pharmacist } from '../../services/types/Pharmacist';
import { confirmationAlert } from '../../util/systemAlert';

export const PharmacistDetailsPage: React.FC<RouteComponentProps<{}>> = (
    props: RouteComponentProps<{}> | any,
): JSX.Element => {
    const { id, pharmacyID } = props.match.params;
    const { loading } = useSelector((state: any) => ({
        loading: state.pharmacies.loading,
    }));
    const dispatch = useDispatch();
    const [pharmacist, setPharmacist] = React.useState<Pharmacist | undefined>(undefined);


    const getSinglePharmacist = React.useCallback(async () => {
        const response: any = await dispatch(getPharmacist());

        if (!response.success) {
            return setErrorAlert(response.message);
        }

        const result = response.data.find((pharmacist: Pharmacist) => pharmacist.id === id);

        setPharmacist(result);
    }, [dispatch, id]);

    React.useEffect(() => {
        getSinglePharmacist();
    }, [getSinglePharmacist]);

    const removePharmacy = () => {
        if (!pharmacist) {
            return;
        }

        confirmationAlert({
            title: 'Are you sure you want to remove this user?',
            text: "This change is permanent!",
            confirmButtonText: 'Yes, remove it!',
            successConfirmation: {
                title: 'remove!',
                text: 'User has been removed.',
            },
            callback: async () => await dispatch(removePharmacistPharmacy({ id: pharmacist.id, pharmacyId: pharmacyID })),
            redirect: () => window.location.replace(`/dashboard/pharmacy-details/${pharmacyID}/pharmacists`),
        });

    }

    return (
        <section className="pharmacist-details-page">
            {loading || !pharmacist ? (
                <Loading />
            ) : (
                    <>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Link to={`/dashboard/pharmacy-details/${pharmacyID}/pharmacists`}>
                                        Pharmacists List
                                    </Link>
                                    <Typography color="textPrimary">
                                        Pharmacist Details
                                    </Typography>
                                </Breadcrumbs>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Card className={'contact-info'}>
                                    <CardContent>
                                        <div className="header">
                                            <span className="name">
                                                {pharmacist.firstName} {pharmacist.lastName}
                                            </span>
                                            <span className="role">{pharmacist.role} </span>
                                            <span className={`ont-badge ${pharmacist.isActive ? 'active' : 'archived'}`}>
                                                {pharmacist.isActive ? 'Active' : 'Archived'}
                                            </span>
                                        </div>
                                        <div className="detail-btn-delete" onClick={removePharmacy}>
                                            <ThemeButton
                                                text="Remove from this pharmacy"
                                                clases="tc-btn"
                                                color="error"
                                                variant="contained"
                                            />
                                        </div>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={6} md={8}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <Card className={'contact-info'}>
                                            <CardContent>
                                                <div className="pharmacist-info">
                                                    <div className="contact-header">
                                                        <span>Contact Information</span>
                                                    </div>
                                                    <div className="info-container">
                                                        <div className="info">
                                                            <span className="label">Email</span>
                                                            <span className="info-value">{pharmacist.email}</span>
                                                        </div>
                                                        <div className="info">
                                                            <span className="label">Phone Number</span>
                                                            <span className="info-value">
                                                                {pharmacist.phoneNumber}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <Card className={'contact-info'}>
                                            <CardContent>test</CardContent>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                )}
        </section>
    );
};
