export interface Data {
  id: string;
  client: string;
  isActive: boolean;
  createdAt: Date;
  udatedAt: Date;
}

export const createApiKeysData = (
  id: string,
  client: string,
  status: boolean,
  created: Date,
  udated: Date,
) => ({
  id,
  client,
  status,
  created,
  udated,
});
