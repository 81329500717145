import * as React from 'react';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ViewAgendaIcon from '@material-ui/icons/ViewAgenda';
import PermIdentityOutlinedIcon from '@material-ui/icons/PermIdentityOutlined';
// import ChatBubbleOutlineOutlinedIcon from '@material-ui/icons/ChatBubbleOutlineOutlined';
// import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import BusinessIcon from '@material-ui/icons/Business';
import { Divider } from '@material-ui/core';
import './menu.scss';
import { NavLink, Link } from 'react-router-dom';
import { useRole } from '../../../hooks/useRole';
import { DeveloperMode } from '@material-ui/icons';

interface MenuProps {
  toggleAction: string;
}

export const Menu: React.FC<MenuProps> = ({
  toggleAction,
}: MenuProps): JSX.Element => {
  const { isSystemAdmin } = useRole();

  return (
    <span className={`menu-host ${toggleAction} `}>
      <ul className="menu-ul">
        <li className="menu-li">
          <Link
          className="item-link"
            to="/dashboard">
            <DashboardIcon className="item-icon" />
            <span className="item-text">Dashboard</span>
          </Link>
        </li>

       {isSystemAdmin && 
          <li className="menu-li">
            <NavLink
              activeClassName="active"
              className="item-link"
              to="/dashboard/user-list">
              <PermIdentityOutlinedIcon className="item-icon" />

              <span className="item-text">Employee List</span>
            </NavLink>
          </li>
       }
        <li className="menu-li">
            <NavLink
              activeClassName="active"
              className="item-link"
            to="/dashboard/pharmacies-list">
              <ViewAgendaIcon className="item-icon" />

              <span className="item-text">Pharmacy List</span>
            </NavLink>
        </li>
        <li className="menu-li">
          <NavLink
            activeClassName="active"
            className="item-link"
            to="/dashboard/chain-list">
            <BusinessIcon className="item-icon" />

            <span className="item-text">Chain List</span>
          </NavLink>
        </li>
        
        <li className="divider-item">
          <Divider className="divider" />
        </li>

        {/* {isSystemAdmin && (
          <li className="menu-li">
            <NavLink
              activeClassName="active"
              className="item-link"
              to="/dashboard/settings">
              <MoreHorizIcon className="item-icon" />
              <span className="item-text">Setting</span>
            </NavLink>
        </li>
        )} */}
        {isSystemAdmin && 
          <li className="menu-li">
            <NavLink
              activeClassName="active"
              className="item-link"
              to="/dashboard/generate_api_key_group">
              <DeveloperMode className="item-icon" />
              <span className="item-text">Groups API Keys</span>
            </NavLink>
        </li>
        }
      </ul>
    </span>
  );
};
