import * as React from 'react';
import { TextField, MenuItem } from '@material-ui/core';

interface Props {
  required?: boolean;
  label: string;
  value: string;
  onChange: any;
  options: Options[];
}

interface Options {
  value: string | number;
  label: string;
}

// tslint:disable-next-line: typedef
export const SelectField: React.FC<Props> = ({
  label,
  value,
  onChange,
  options,
  required = false,
}): JSX.Element => (
  <>
    <TextField
      required={required}
      select={true}
      label={label}
      fullWidth={true}
      value={value}
      onChange={onChange}>
      {options.map((option: Options) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  </>
);
