import { User } from './User';

// action strings
export const GET_EMPLOYEES_SUCCESS = 'GET_EMPLOYEES_SUCCESS';
export const GET_EMPLOYEES_FAIL = 'GET_EMPLOYEES_FAIL';
export const GET_EMPLOYEES_DETAILS_SUCCESS = 'GET_EMPLOYEES_DETAILS_SUCCESS';
export const GET_EMPLOYEES_DETAILS_FAIL = 'GET_EMPLOYEES_DETAILS_FAIL';
export const DELETE_EMPLOYEES_DETAILS_SUCCESS =
  'DELETE_EMPLOYEES_DETAILS_SUCCESS';
export const DELETE_EMPLOYEES_DETAILS_FAIL = ' DELETE_EMPLOYEES_DETAILS_FAIL';
export const UPDATE_EMPLOYEES_DETAILS_SUCCESS =
  'UPDATE_EMPLOYEES_DETAILS_SUCCESS';
export const UPDATE_EMPLOYEES_DETAILS_FAIL = 'UPDATE_EMPLOYEES_DETAILS_FAIL';
export const STATUS_EMPLOYEES_DETAILS_SUCCESS =
  'STATUS_EMPLOYEES_DETAILS_SUCCESS';
export const STATUS_EMPLOYEES_DETAILS_FAIL = 'STATUS_EMPLOYEES_DETAILS_FAIL';
export const CREATE_EMPLOYEE_SUCCESS = 'REATE_EMPLOYEE_SUCCESS';
export const CREATE_EMPLOYEE_FAIL = 'CREATE_EMPLOYEE_FAIL';

export const DELETE_MULTIPLE_EMPLOYEES_SUCCESS =
  'DELETE_MULTIPLE_EMPLOYEES_SUCCESS';
export const DELETE_MULTIPLE_EMPLOYEES_ERROR =
  'DELETE_MULTIPLE_EMPLOYEES_ERROR';

export interface EmployeesStates {
  loading: boolean;
  users?: User[] | null | undefined | any;
  user?: User | null | undefined | any;
}

export interface GetEmployeesAction {
  type: typeof GET_EMPLOYEES_SUCCESS;
  payload: User[];
}

export interface ErrorGetEmmployeeAction {
  type: typeof GET_EMPLOYEES_FAIL;
  payload: User[];
}

export interface GetEmployeesDetailsAction {
  type: typeof GET_EMPLOYEES_DETAILS_SUCCESS;
  payload: User;
}
export interface ErrorGetEmmployeeDetailsAction {
  type: typeof GET_EMPLOYEES_DETAILS_FAIL;
  payload: User;
}

export interface DeleteEmployeesDetailsAction {
  type: typeof DELETE_EMPLOYEES_DETAILS_SUCCESS;
  payload: [];
}
export interface ErrorDeleteEmmployeeDetailsAction {
  type: typeof DELETE_EMPLOYEES_DETAILS_FAIL;
  payload: [];
}

export interface CreateEmployeesAction {
  type: typeof CREATE_EMPLOYEE_SUCCESS;
  payload: User[];
}

export interface ErrorCreateEmmployeeAction {
  type: typeof CREATE_EMPLOYEE_FAIL;
  payload: User[];
}

export interface DeleteMultipleEmployeesAction {
  type: typeof DELETE_MULTIPLE_EMPLOYEES_SUCCESS;
  payload: User;
}

export interface DeleteMultipleEmployeesError {
  type: typeof DELETE_MULTIPLE_EMPLOYEES_ERROR;
  payload: User;
}
