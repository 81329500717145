import * as React from 'react';
import './sidebar-toggle.scss';

interface SideBarToggleProps {
  toggle: boolean;
  changeNavState: () => void;
}

export const SideBarToggle: React.FC<SideBarToggleProps> = ({
  toggle,
  changeNavState,
}: SideBarToggleProps): JSX.Element => (
  <div
    className={`side-bar-toggle ${toggle ? 'active' : ''}`}
    onClick={changeNavState}>
    <div className="toggle">
      <div className="thumb" />
    </div>

    <div className="label">Toggle sidebar</div>
  </div>
);
