import './pharmacists-list.scss';

/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/label-has-associated-control */
import * as React from 'react';

import { Alert, AlertTitle } from '@material-ui/lab';
import { Breadcrumbs, Grid, MenuItem, Select, Typography } from '@material-ui/core';
import {Data, createUserData} from '../../interfaces/userData';
import {
  DataTable,
  Loading,
  ThemeButton
} from '../../layout/App.components';
import { useDispatch, useSelector } from 'react-redux';

import { AddPharmacistModal } from './AddPharmacistModal';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { Link } from 'react-router-dom';
import { Pharmacist } from '../../services/types/Pharmacist';
import { RouteComponentProps } from 'react-router';
import { TableOption } from '../../layout/ui/data-table/DataTable';
import { deleteMultipleEmployees } from '../../services/actions/employees';
import { getPharmacies } from '../../services/actions/pharmacy';
import { pharmacyReports } from '../../services/actions/report';
import { reduxState } from '../../services/types/reduxStates';
import { useRole } from '../../hooks/useRole';

// import { warningAlert } from '../../util/systemAlert';


export const PharmacistsListPage: React.FC<RouteComponentProps<{}>> = (
  props: RouteComponentProps<{}> | any,
): JSX.Element => {
  const { id } = props.match.params;

  const { loading: reportLoading, reports } = useSelector(
    (state: reduxState) => state.pharmacyReport,
  );

  const { pharmacy, loading } = useSelector((state: any) => ({
    pharmacy: state.pharmacies.pharmacies.find(
      (pharmacy: any) => pharmacy.id === id,
    ),
    loading: state.pharmacies.loading,
  }));
  const [userStatus, setUserstatus] = React.useState<string | unknown>('');
  const dispatch = useDispatch();
  const { isSystemAdmin, isAdmin } = useRole();

  // modal
  const [open, setOpen] = React.useState(false);
  const handleClose = (): void => {
    setOpen(false);
  };

  React.useEffect(() => {
    dispatch(getPharmacies());
  }, [dispatch, id]);

  const loadReports = React.useCallback(async () => {
    if (!loading && pharmacy) {
      dispatch(pharmacyReports(pharmacy.id));
    }
  }, [dispatch, loading, pharmacy]);

  React.useEffect(() => {
    loadReports();
  }, [loadReports]);
 
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setUserstatus(event.target.value);
  };

  const filterUserData = (pharmacist: Pharmacist) => {
    switch (userStatus) {
      case 'Pharmacist':
        return pharmacist.role === 'Pharmacist';
      case 'Administrator':
        return pharmacist.role === 'Administrator';
      case 'Owner':
        return pharmacist.role === 'Owner';
      case 'ChiefPharmacist':
        return pharmacist.role === 'ChiefPharmacist';
      case 'PharmacyTechnician':
        return pharmacist.role === 'PharmacyTechnician';
      default:
        return pharmacist;
    }
  };


  let rows: Data[] | [] = [];

  if (!loading && pharmacy) {
    rows = pharmacy.pharmacists
      .filter((pharmacist: Pharmacist) => filterUserData(pharmacist))
      .map((pharmacist: Pharmacist) =>
        createUserData(
          pharmacist.id,
          pharmacist.avatar,
          pharmacist.isActive,
          pharmacist.role,
          `${pharmacist.firstName} ${pharmacist.lastName}`,
          pharmacist.phoneNumber,
          pharmacist.email,
        ),
      );
  }

  const tableHeader: string[] = [
    'Status',
    'Role',
    'Username',
    'Phone Number',
    'Email',
    'View'
  ];

  const tableOption: TableOption<string[], Data[]> = {
    tableHeader,
    tableData: rows,
  };



  return (
    <>
      {loading && !pharmacy ? 
        <Loading />
       : 
          <>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Breadcrumbs aria-label="breadcrumb">
                  <Link to={`/dashboard/pharmacy-details/${id}`}>{pharmacy.name} Details </Link>
                  <Typography color="textPrimary">{pharmacy.name} Pharmacists List</Typography>
                </Breadcrumbs>
              </Grid>
            {!reportLoading && reports && reports?.pharmacistsCount <= 0 &&
              <Grid item xs={12}>
                <Alert severity="warning">
                  <AlertTitle>Warning</AlertTitle>
                   To enable the pharmacy, please add an owner.
                </Alert>
              </Grid>
            }
              <Grid item xs={12}>
                <section className="list-page-header">
                  <div className="filter">
                    <label className="label" title="user role">
                      Role:
              </label>
                    <Select
                      value={userStatus}
                      onChange={handleChange}
                      displayEmpty
                      name="status">
                      <MenuItem value="">
                        <em>All</em>
                      </MenuItem>
                      <MenuItem value="Pharmacist">Pharmacist</MenuItem>
                      <MenuItem value="Administrator">Administrator</MenuItem>
                      <MenuItem value="Owner">Owner</MenuItem>
                      <MenuItem value="ChiefPharmacist">ChiefPharmacist</MenuItem>
                      <MenuItem value="PharmacyTechnician">PharmacyTechnician</MenuItem>
                    </Select>
                  </div>
                  <div className="create-btn" onClick={() => setOpen(true)}>
                    {(isSystemAdmin || isAdmin) && 
                      <ThemeButton
                        text="Add new Pharmacist"
                        clases="tc-btn"
                        color="primary"
                        variant="contained"
                        />}
                  </div>
                </section>

                <AddPharmacistModal
                  isOpen={open}
                  handleClose={handleClose}
                  pharmacyId={id}
                  pharmacistList={pharmacy.pharmacists}
                  />

                <DataTable
                  tableData={tableOption}
                  isActiveDelete={false}
                  handleMultipleDelete={selected => deleteMultipleEmployees(selected)}
                  generateCell={(row,column) => {
                    const details =
                      `/dashboard/pharmacist-details/${row.id}/${id}`;
                    switch(column){
                      case 'email': return row.email;
                      case 'phone number': return row.phoneNumber;
                      case 'role': return row.role;
                      case 'username': {
                        return (
                          <div className="user-wrap">
                            <Link to={details} className="edit-wrap">
                              <span className="name dot-wrap" title={row.username}>
                                {' '}
                                {row.username}{' '}
                              </span>
                            </Link>
                          </div>
                        );
                      }
                      case 'status':{
                        const value: string = row.status ? 'Active' : 'Archived';
                        return (
                          <span className={'ont-badge '.concat(value.toLowerCase())}>
                            {value}
                          </span>
                        );
                      }
                      case 'view':{
                        return (
                          <Link to={details} className="edit-wrap">
                          <div className="edit-text">
                            View
                          </div>
                          <div className="icon">
                            <EditOutlinedIcon />
                          </div>
                        </Link>
                        )
                      }
                      default:
                        return null
                    }
                  }}
                  />
              </Grid>
            </Grid>
          </>
        }
    </>
  );
};
