import {
  AUTH_ERROR,
  GET_USER_PROFILE_SUCCESS,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT,
  USER_LOADED,
} from '../types/User';

import { API_HOST } from '../../../config';
import { AnyAction } from 'redux';
import { Dispatch } from 'react';
import { REMOVE_ERROR_ALERT } from '../types/Alert';
import axios from 'axios';
import { setAuthToken } from '../../util/setauthToken';
import { setErrorAlert } from './alert';

export const loadUser = (): any => async (
  dispatch: Dispatch<AnyAction>,
): Promise<void> => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    const response = await axios.get(`${API_HOST}/ontime_admin/users/auth`);

    dispatch({
      type: USER_LOADED,
      payload: response.data,
    });

    dispatch({
      type: GET_USER_PROFILE_SUCCESS,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });

    dispatch(logout());
  }
};

export const login = (email: string, password: string): any => async (
  dispatch: Dispatch<AnyAction>,
): Promise<void> => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify({ email, password });

  try {
    const response = await axios.post(
      `${API_HOST}/ontime_admin/users/authentication`,
      body,
      config,
    );

    dispatch({
      type: LOGIN_SUCCESS,
      payload: response.data,
    });

    dispatch({
      type: GET_USER_PROFILE_SUCCESS,
      payload: response.data,
    });

    dispatch({
      type: REMOVE_ERROR_ALERT,
    });

    dispatch(loadUser());
  } catch (error: any) {
    dispatch(
      setErrorAlert(
        error.response && error.response.data
          ? error.response.data.message
          : '',
      ),
    );
    dispatch({
      type: LOGIN_FAIL,
    });
  }
};

export const logout = (): any => (dispatch: Dispatch<AnyAction>) => {
  dispatch({
    type: LOGOUT,
  });
};

export const emailConfirmation = (data: any): any => async (
  dispatch: Dispatch<AnyAction>,
): Promise<boolean> => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = JSON.stringify(data);

  try {
    await axios.post(`${API_HOST}/ontime_rx/email-confirmation`, body, config);

    dispatch({
      type: REMOVE_ERROR_ALERT,
    });
    return true;
  } catch (error: any) {
    dispatch(
      setErrorAlert(
        error.response && error.response.data
          ? error.response.data.message
          : '',
      ),
    );
    return false;
  }
};
