import * as React from 'react';
import './dashboard.scss';
import { CountingCard, CircularChart, Loading } from '../../layout/App.components';
import { LinearProgress, withStyles, Grid } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { reduxState } from '../../services/types/reduxStates';
import { getReports } from '../../services/actions/report';


const ColorLinearProgress = withStyles({
  colorPrimary: {
    backgroundColor: '#b2dfdb',
  },
  barColorPrimary: {
    backgroundColor: '#00695c',
  },
})(LinearProgress);


export const DashboardPage: React.FC = (): JSX.Element => {
  const { user, loading } = useSelector((state: reduxState) => state.user);
  const { report } = useSelector((state: reduxState) => state.report);

  const dispatch = useDispatch();

  React.useEffect(() => {
    let unmounted = false;
    if (user) {
      if (!unmounted) {
      dispatch(getReports());
      }
    }
    return () => { unmounted = true };
  }, [user, dispatch])


  let dataPhatients = {
    labels: ['Female', 'Male'],
    datasets: [
      {
        data: [0, 0], //this data came from api
        backgroundColor: ['#2ED47A', '#FFB946 '],
        hoverBackgroundColor: ['#2ED47A', '#FFB946 '],
      },
    ],
  };
  let dataPharmacies = {
    labels: ['Yes', 'No'],
    datasets: [
      {
        data: [0, 0], //this data came from api
        backgroundColor: ['#2ED47A', '#F7685B'],
        hoverBackgroundColor: ['#2ED47A', '#F7685B'],
      },
    ],
  };


  const getPercentData = (data: number[], count: number) => {
    const sum: number = data.reduce((a: number, b: number) => a + b, 0);
    const obtained: number = Math.round(count);
    const total: number = sum;
    const percent: number = obtained * 100 / total;
    const percentCal: number = Number.isNaN(Math.round(percent)) ? 0 : Math.round(percent);
    return percentCal;
  }

  let androidCount: number = 0;
  let IOSCount: number = 0;

  if (!loading && report) {
    const reportPatientDemographic = report.mobileAppUsers.demographic;
    const reportPharmacyRx30 =  report.activePharmacies.hasRx30

    dataPhatients.datasets[0].data = [
      reportPatientDemographic.femaleCount, 
      reportPatientDemographic.maleCount
    ];

    dataPharmacies.datasets[0].data = [
      reportPharmacyRx30.yesCount,
      reportPharmacyRx30.noCount
    ];

    const OSData = [
      report.mobileAppOS.AndroidCount,
      report.mobileAppOS.IOSCount
    ]

    androidCount = getPercentData(OSData, report.mobileAppOS.AndroidCount);
    IOSCount = getPercentData(OSData, report.mobileAppOS.IOSCount);

  }

  


  return (
    <>
      {loading || !report ? (
        <Loading />
      ) : (
            <Grid container spacing={3}>
              <Grid item xs={6} md={4}>
                <div className="count-item">
                  <CountingCard title="Active pharmacies" count={report ? report.activePharmacies.pharmaciesCount : 0} />
                </div>
                </Grid>
              <Grid item xs={6} md={4}>
                <div className="count-item">
                  <CountingCard title="Active Mobile Users" count={report ? report.mobileAppUsers.userCount : 0} />
                </div>
                </Grid>
              <Grid item xs={12} md={4}>
                <div className="count-item">
                  <CountingCard title="App Downloads">
                    <div className="downloads-container">
                      <div className="downloads-metric">
                        <h6 style={{ display: 'flex' }}>Android Downloads <span style={{ marginLeft: 'auto' }}>({androidCount}%)</span></h6>
                        <ColorLinearProgress variant="determinate" value={androidCount} />
                      </div>
                      <div className="downloads-metric">
                        <h6 style={{ display: 'flex' }}>IOS Downloads <span style={{ marginLeft: 'auto' }}>({IOSCount}%)</span></h6>
                        <ColorLinearProgress variant="determinate" value={IOSCount} />
                      </div>
                    </div>
                  </CountingCard>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="chart-item">
                  <CircularChart title="Patients Demographics" data={dataPhatients} />
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="chart-item">
                  <CircularChart title="RX30 Customer" data={dataPharmacies} />
                </div>
              </Grid>
            </Grid>
        )}
    </>
  );
};
